import GoogleButtonSignup from './GoogleButtonSignup';
import './Signup.scss';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Form, FormInput, FormGroup } from 'shards-react';
import logo from '../images/letsfika.jpg';
import axios from 'axios';
import config from '../config';
var setCookie = require('set-cookie-parser');

// const signUpWithoutGoogle = () => {};

function Signup() {
  axios.defaults.withCredentials = true;
  const history = useHistory();

  const [user, setUser] = useState({
    email: '',
    name: '',
    dob: '',
    imageUrl: 'figureoutlater.com',
    password: '',
  });
  const [confirmedPassword, setConfirmedPassword] = useState('');

  const whetherAnyInputsEmpty = () => {
    return Object.values(user).some((key) => key === null || key === '');
  };

  const passwordsMatch = () => {
    return confirmedPassword === user.password;
  };

  const requestSignUp = async () => {
    const response = await axios.post(`${config.apiUrl}/api/auth/signup`, user, { withCredentials: true });
    console.log(response);
    var cookies = setCookie.parse(response, {
      decodeValues: true  // default: true
    });
    console.log(cookies);
    if (response.status === 200) {
      history.push('/');
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-header-container">
        <img className="signup-logo" src={logo} alt="" />
        <h4>Sign up for FREE</h4>
        <h6>to access more content and bonus features</h6>
        <GoogleButtonSignup />
        or sign up with your email address {user.email} {user.id}
      </div>
      <Form className="signup-form">
        <FormGroup>
          <label htmlFor="#email">What is your email?</label>
          <FormInput
            onChange={(e) => {
              setUser({ ...user, email: e.target.value });
            }}
            id="#email"
            placeholder="Enter your email"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="#Name">
            Please give us your name:
          </label>
          <FormInput
            onChange={(e) => {
              setUser({ ...user, name: e.target.value });
            }}
            id="#Name"
            placeholder="Name (required)"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="#username">What is your date of birth?</label>
          <input
            onChange={(e) => {
              setUser({ ...user, dob: e.target.value });
            }}
            type="date"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="#password">Please create a Password</label>
          <FormInput
            onChange={(e) => {
              setUser({ ...user, password: e.target.value });
            }}
            type="password"
            id="#password"
            placeholder="Create a password"
          />
          <FormInput
            onChange={(e) => {
              setConfirmedPassword(e.target.value);
            }}
            type="password"
            id="#confirmPassword"
            placeholder="Confirm password"
          />
        </FormGroup>
        <Button
          onClick={requestSignUp}
          disabled={whetherAnyInputsEmpty() || !passwordsMatch()}
          block
          theme="success"
        >
          Sign Up 
        </Button>
        <p>
          Already have an account? <a href="/login">login</a>
        </p>
      </Form>
    </div>
  );
}

export default Signup;
