import config from '../config';
import axios from 'axios';
import React, { useEffect, useState, useMemo } from 'react';
import { Button } from 'shards-react';
import './manager.scss';
import { Pagination, Search } from '../components/table';

import UpdateEventPartnerInfo from './EventPartnerComponent/updateEventPartnerInfo.js';
import CreateEventPartner from './EventPartnerComponent/createEventPartner.js';
import PastEventsEventPartner from './EventPartnerComponent/pastEvents.js';
import AssignmentForm from './EventPartnerComponent/assignmentForm.js';

const EventPartnerManager = () => {
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const ITEMS_PER_PAGE = 5;

    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUserTable, setShowUserTable] = useState(true);
    const [showUserDetailedInfo, setShowUserDetailedInfo] = useState(false);
    const [showUserPastEvents, setshowUserPastEvents] = useState(false);
    const [showAssignmentForm, setShowAssignmentForm] = useState(false);


    


    let createForm = (
        <div className="createEventPartner">
          <br/>
          <Button onClick={() => {
            setShowCreateForm(false);
            setShowAssignmentForm(false);
            setShowUserDetailedInfo(false);
            setShowUserTable(true);
            refreshTable();
          }}>
            Return
          </Button>
          <br/>
          <CreateEventPartner config={config}></CreateEventPartner> 
        </div>
      );
  
      useEffect(() => {
        getUsers();
        getAdminLevel();
      }, []);
  
      const [adminLevel, setAdminLevel] = useState('Loading...');
      
      const getAdminLevel = async () => {
          axios.get(`${config.apiUrl}/api/admin/adminlevel`)
        .then( (res)=>{
          console.log(res)
          setAdminLevel(map[res.data.adminLevel]);
        })
        .catch((e)=>{
          console.log(e);
        })
      }
    
      const tableData = useMemo(() => {
        let computedUsers = users;
    
        if (search) {
          computedUsers = computedUsers.filter((data) =>
            data.name.toLowerCase().includes(search.toLowerCase())
          );
        }
    
        setTotalItems(computedUsers.length);
    
        return computedUsers.slice(
          (currentPage - 1) * ITEMS_PER_PAGE,
          (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
      }, [users, currentPage, search])
    
      const getUsers = async(e) => {
        // e.preventDefault();
        const response = await axios.get(`${config.apiUrl}/api/admin/letsfika_eventpartners`);
        console.log(response.data);
        setUsers(response.data);
      }
  
      const getUser = async (n) => {
        const response = await axios.get(`${config.apiUrl}/api/admin/letsfika_eventpartner/`+ n);
        console.log(response.data);
        console.log(n)
        setUser(response.data);
      };
    
      const refreshTable = getUsers;
    
      const deleteUser = async (eventId) => {
        const response = await axios.post(`${config.apiUrl}/api/admin/letsfika_eventpartner/delete/${eventId}`);
        console.log(response.data);
        refreshTable();
      }
    
      const map = {
        0: 'Super Admin',
        1: 'Regular Admin',
        2: 'Editor',
        3: 'Normal User'
      }   

      const assigmentFormRef = React.createRef();

      let assigmentForm = (
        <div>
          <br/>
          <Button onClick={() => {
            if (assigmentFormRef.current.state.eventpartner!==null){
              setUser(assigmentFormRef.current.state.eventpartner)
            }
            setShowCreateForm(false);
            setShowAssignmentForm(false);
            setShowUserDetailedInfo(true);
            setShowUserTable(false);
            refreshTable();
          }}>
            Return
          </Button>
          <AssignmentForm ref={assigmentFormRef} eventpartner={user} config={config}></AssignmentForm> 
        </div>
      );

      let pastEventsTable = (
        <div>
          <br/>
          <Button onClick={() => {
            setShowUserDetailedInfo(true);
            setShowCreateForm(false);
            setShowUserTable(false);
            setshowUserPastEvents(false);
            setShowAssignmentForm(false);
            // refreshTable();
          }}>
            Return
          </Button>
  
          <PastEventsEventPartner 
            events={user.events} 
            eventId={user.eventId}
            config={config}>
          </PastEventsEventPartner>
        </div>
      );
  
      
      let userForm = (
        <div>
          <br/>
            <Button onClick = {() => {
                setShowUserTable(true);
                setShowUserDetailedInfo(false);
                setShowCreateForm(false);
                setShowAssignmentForm(false);
                refreshTable();
                }}>Return
            </Button>

            <Button onClick={ () => { 
                setShowAssignmentForm(true);
                setShowCreateForm(false);
                setShowUserDetailedInfo(false);
                setShowUserTable(false);
                }}> 
                Upcoming Event 
            </Button>

            <Button onClick={ () => { 
                console.log(user)
                setShowCreateForm(false);
                setShowUserDetailedInfo(false);
                setShowUserTable(false);
                setshowUserPastEvents(true);          
                setShowAssignmentForm(false);
            }}> 
                Past Hosted Events
            </Button>
  
          <br/>
          <br/>
  
          <UpdateEventPartnerInfo 
            user={user}
            config={config}>
          </UpdateEventPartnerInfo>
        
        </div>
      );
    
      let userTable = '';
      if (tableData.length !== 0) {
        userTable = (
          <div>
            <br/>
  
            <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            <div className="col-md-6">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
  
            <table>
              <thead>
                <tr>
                  <td>Company Name</td>
                  <td>Email(required)</td>
                  <td>Name</td>
                  <td>Phone</td>
                  <td>Prefered Event Date</td>
                  <td>Delete</td>
                </tr>
              </thead>
              <tbody>
                {tableData.map((result) => {
                  return (
                    <tr key={result._id}>
                      <td>
                        <Button value={result.name} onClick={() => {
                          getUser(result.eventId);
                          setShowUserDetailedInfo(true);
                          setShowUserTable(false)
                          setUser(result)
                        }}> {result.company}
                        </Button>
                      </td>
                      <td>{result.email}</td>
                      <td>{result.name}</td>
                      <td>{result.phone}</td>
                      <td>{result.preferedEventDate!==undefined && 
                           result.preferedEventDate.length>=10 ? result.preferedEventDate.substring(0,10) : ''}</td>
                      <td>
                        <Button onClick={
                          () => { 
                            if (window.confirm('Are you sure you wish to delete this Event Partner?')){
                                console.log(result.email)
                              deleteUser(result.eventId);
                            } 
                          }
                        }> Delete
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
            
        ); 
      } else {
        userTable = <h2>No Event Partner Found(Or click Refresh to refresh the table)</h2>;
      }
    
      return (
        <div>
          <h1>Admin Portal (Event Partner)</h1>
          <div className="adminBanner">Admin Level is: {adminLevel}</div>
          <br />
  
          <Button onClick={getUsers}>Refresh</Button>
          <Button onClick={
                        () => { 
                          setShowCreateForm(true);
                          setShowAssignmentForm(false);
                          setShowUserDetailedInfo(false);
                          setShowUserTable(false);
                        }
                      }> Create Event Partner
          </Button>
          
  
          <div> 
            {showUserTable===true ? userTable: ''}
            {showCreateForm===true ? createForm: ''}
            {showUserDetailedInfo===true ? userForm: ''}
            {showUserPastEvents===true ? pastEventsTable : ''}
            {showAssignmentForm===true ? assigmentForm : ''}
          </div>
  
          
  
         
        </div>
      );
}

export default EventPartnerManager;