import Dropdown from './dropdown';

const Profile = () => {
  return (
    <div className="nav-profile-container">
      <Dropdown />
    </div>
  );
};

export default Profile;
