import React from 'react';
import Select from 'react-select';

const Categories = (props) => {
  return (
    <>
      <Select
        options={props.category}
        isDisabled={false && props.privilege}
        onChange={props.handleCategory}
        defaultValue={{ value: props.initial, label: props.initial }}
      />
    </>
  );
};

export default Categories;
