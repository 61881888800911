import axios from 'axios';
import React, {Component} from "react";

class CreateStrategicPartner extends Component {
    constructor (props) {
        super(props)
        this.state = {
            company: '',
            companyUrl: '',
            city: '',
            state: '',
            address: '',
            zipCode: '',
            email: '',
            phone: '',
            
            category: '',
            startDate: '',
            endDate: '',

            contactName: '',
            contactJob: '',
            attnBillingName: ''
        }
    }

    createStrategicPartner = async (e) => {
        e.preventDefault();
        const info = {...this.state}
        try{
            const res = await axios.post(`${this.props.config.apiUrl}/api/admin/letsfika_strategicpartner`, {info})

            this.setState({
                company: '',
                companyUrl: '',
                city: '',
                state: '',
                address: '',
                zipCode: '',
                email: '',
                phone: '',
                category: '',
                startDate: '',
                endDate: '',
                contactName: '',
                contactJob: '',
                attnBillingName: ''
            })
            console.log(res);
        } catch (e) {
            console.log(e);
        }
    }

    render(){
        return(
            <div>
                <label>Register New Volunteer below: </label>
                <br/>
                <form onSubmit={this.createStrategicPartner}>
                    <label>
                        Company Name(required): 
                        <input type="text" value={this.state.company} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({company: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Company Url: 
                        <input type="text" value={this.state.companyUrl} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({companyUrl: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Partnership category: 
                        <input type="text" value={this.state.category} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({category: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Sponsorship Start Date: 
                        <input type="text" value={this.state.startDate} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({startDate: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Sponsorship Expiration Date: 
                        <input type="text" value={this.state.endDate} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({endDate: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Key Contact Name: 
                        <input type="text" value={this.state.contactName} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({contactName: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Key Contact Job Title: 
                        <input type="text" value={this.state.contactJob} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({contactJob: e.target.value})}
                        } />
                    </label>
                    <br/>
                    
                    <label>
                        Email(required): 
                        <input type="text" value={this.state.email} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({email: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Phone: 
                        <input type="text" value={this.state.phone} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({phone: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Billing Address: 
                        <input type="text" value={this.state.address} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({address: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        City: 
                        <input type="text" value={this.state.city} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({city: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        State: 
                        <input type="text" value={this.state.state} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({state: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Zipcode: 
                        <input type="text" value={this.state.zipCode} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({zipCode: e.target.value})}
                        } />
                    </label>
                    <br/>


                    <input type="submit" value="Submit" />

                </form>
            </div>
        );
    }



};



export default CreateStrategicPartner;