import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'shards-react';
import VideoManager from './videoManager';
import DisplayManager from './displayManager';
import BlogManager from './blogManager';
import PodcastManager from './podcastManager';
import UserManager from './userManager';
import VolunteerManager from './volunteerManager';
import StrategicPartnerManager from './strategicPartnerManager';
import SponsorManager from './sponsorManager';
import EventPartnerManager from './eventpartnerManager';
import ApplicationManager from './applicationManager';

import CalendarManager from './calendar/CalendarManager';
import './adminPortal.scss';
import React, { useEffect, useState } from 'react';
import config from '../config';
import axios from 'axios';

const AdminPortal = () => {
  const routes = [
    {
      path: '/admin/display',
      name: 'Display',
      component: () => <DisplayManager />,
    },
    {
      path: '/admin/videos',
      name: 'Videos',
      component: () => <VideoManager />,
    },
    {
      path: '/admin/podcasts',
      name: 'Podcasts',
      component: () => <PodcastManager />,
    },
    {
      path: '/admin/blogs',
      name: 'Blogs',
      component: () => <BlogManager />,
    },
    {
      path: '/admin/users',
      name: 'Users',
      component: () => <UserManager />,
    },
    {
      path: '/admin/volunteers',
      name: 'Volunteers',
      component: () => <VolunteerManager />,
    },
    {
      path: '/admin/sponsors',
      name: 'Sponsors',
      component: () => <SponsorManager />,
    },
    {
      path: '/admin/strategicPartners',
      name: 'StrategicPartners',
      component: () => <StrategicPartnerManager />,
    },
    {
      path: '/admin/eventPartner',
      name: 'EventPartner',
      component: () => <EventPartnerManager />,
    },
    {
      path: '/admin/calendar',
      name: 'Calendar',
      component: () => <CalendarManager />,
    },
    {
      path: '/admin/applications',
      name: 'Applications',
      component: () => <ApplicationManager />,
    },
  ];

  const [adminLevel, setAdminLevel] = useState('Loading...');
  const map = {
    0: 'Super Admin',
    1: 'Regular Admin',
    2: 'Editor',
    3: 'Normal User',
  };

  useEffect(() => {
    axios
      .get(`${config.apiUrl}/api/admin/adminlevel`)
      .then((res) => {
        console.log(res);
        setAdminLevel(map[res.data.adminLevel]);
      })
      .catch((e) => {
        console.log(e);
        setAdminLevel('user not loggined');
      });
  });

  const aLevels = ['Super Admin', 'Editor', 'Regular Admin'];

  return (
    <Router>
      {!aLevels.includes(adminLevel) ? (
        <div className="adminBanner">
          <label> {adminLevel} </label>
        </div>
      ) : (
        <div className="admin-container">
          <Nav pills className="sidebar-nav-container">
            {routes.map((route) => (
              <NavItem>
                <NavLink>
                  <Link to={route.path}>{route.name}</Link>
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <div>
            <div>
              <Switch>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    children={<route.component />}
                  />
                ))}
              </Switch>
            </div>
          </div>
        </div>
      )}
    </Router>
  );
};

export default AdminPortal;
