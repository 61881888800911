import { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';
import './listen.scss';
import Content from '../content/content';

function Listen() {
  const [source, setSource] = useState('Newest');
  const [categories, setCategories] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [featuredEpisode, setFeaturedEpisode] = useState();

  const getAllCategories = async () => {
    const response = await axios.get(`${config.apiUrl}/api/labels/categories`);
    // console.log(response.data);
    setCategories(response.data);
  };

  const getFeaturedEpisode = async () => {
    const episodes = await axios.get(config.apiUrl + '/api/podcasts/featured');
    // console.log(episodes.data);
    setFeaturedEpisode(episodes.data);
  };

  const getDescriptions = async () => {
    const response = await axios.get(
      `${config.apiUrl}/api/display/page/listen`
    );
    // console.log(response.data);
    let result = new Map();
    response.data.forEach((item) => {
      result[item.type] = item.text;
    });
    console.log(result);
    setDescriptions(result);
  };

  useEffect(() => {
    getDescriptions();
    getAllCategories();
    getFeaturedEpisode();
  }, []);

  return (
    <div>
      <section className="featured-media">
        <div className="constrain-width featured-media-container">
          <div className="featured-media-text-container">
            <h1 className="featured-media-title">{descriptions.Title}</h1>
            <h4 className="featured-media-subtitle">{descriptions.Primary}</h4>
            <div className="category-container">
              {categories.map((item, i) => {
                return (
                  <p className="category-container-item">
                    <span
                      className="category-container-title"
                      onClick={() => {
                        setSource(item.title);
                      }}
                      key={i}
                    >
                      {item.title}
                    </span>
                    {(() => {
                      if (i < categories.length - 1) return '|';
                    })()}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="featured-media-image-container">
            {(() => {
              if (featuredEpisode) {
                return (
                  <iframe
                    className="frame"
                    title={featuredEpisode._id}
                    src={`https://open.spotify.com/embed-podcast/episode/${featuredEpisode._id}`}
                    width="400px"
                    height="250px"
                    frameborder="0"
                    allowtransparency="true"
                    allow="encrypted-media"
                  ></iframe>
                );
              }
            })()}
          </div>
        </div>
      </section>
      <Content type="listen" content="podcasts" title={source} />
    </div>
  );
}

export default Listen;
