import { useEffect, useState } from "react";
import axios from 'axios';
import config from '../config';
import { Button } from 'shards-react';
import moment from 'moment';

const ApplicationManager = () => {
  const [applications, setApplications] = useState([])
  const getApplications = async () => {
    const res = await axios.get(`${config.apiUrl}/api/application`);
    setApplications(res.data);
  }

  useEffect(() => {
    getApplications();
  }, [])

  const modifyStatus = async (id, status) => {
    const res = await axios.post(`${config.apiUrl}/api/application/update`,
      { id, status }
    );
    getApplications();
  }

  return (
    <div>
      <h2>Pending</h2>
      {applications.filter(app => app.status === 'Pending').map((app, i) => {
        return (
          <div style={{ marginLeft: '20px' }}>
            <h4>
              Application for {app.event.title} on {moment(app.event.start).format('MM/DD, YYYY')} to {moment(app.event.end).format('MM/DD, YYYY')}
            </h4>
            <p>Role: {app.role}</p>
            {Object.entries(app.formData).map(([k, v]) => (
              <span>
                <p>{k}: {v}</p>
              </span>
            ))}
            <Button theme="success" onClick={() => { modifyStatus(app._id, 'Approved') }}>Approve</Button>
            <Button theme="danger" onClick={() => { modifyStatus(app._id, 'Rejected') }}>Reject</Button>
          </div>
        )
      })}
      <h2>Approved</h2>
      {applications.filter(app => app.status === 'Approved').map((app, i) => {
        return (
          <div style={{ marginLeft: '20px' }}>
            <h4>
              Application for {app.event.title} on {moment(app.event.start).format('MM/DD, YYYY')} to {moment(app.event.end).format('MM/DD, YYYY')}
            </h4>
            <p>Role: {app.role}</p>
            {Object.entries(app.formData).map(([k, v]) => (
              <span>
                <p>{k}: {v}</p>
              </span>
            ))}
            <Button theme="danger" onClick={() => { modifyStatus(app._id, 'Rejected') }}>Reject</Button>
          </div>
        )
      })}
      <h2>Rejected</h2>
      {applications.filter(app => app.status === 'Rejected').map((app, i) => {
        return (
          <div style={{ marginLeft: '20px' }}>
            <h4>
              Application for {app.event.title} on {moment(app.event.start).format('MM/DD, YYYY')} to {moment(app.event.end).format('MM/DD, YYYY')}
            </h4>
            <p>Role: {app.role}</p>
            {Object.entries(app.formData).map(([k, v]) => (
              <span>
                <p>{k}: {v}</p>
              </span>
            ))}
            <Button theme="success" onClick={() => { modifyStatus(app._id, 'Approved') }}>Approve</Button>
          </div>
        )
      })}
    </div>
  )
}

export default ApplicationManager;