import config from '../config';
import axios from 'axios';
import { Button, Modal, ModalBody, ModalHeader } from 'shards-react';
import React, { useEffect, useState, useMemo } from 'react';
import { TableHeader, Pagination, Search } from '../components/table';
import { Category, Channel } from '../components/options';
import Columns from './contentColumns';
import { format } from 'date-fns';
import Editor from './contentEditor';
import './manager.scss';

const PodcastManager = () => {
  const [podcasts, setPodcasts] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState({ field: '', order: '' });

  const [categories, setCategories] = useState([]);
  const [channels, setChannels] = useState([]);

  const [editor, setEditor] = useState();
  const [modal, setModal] = useState(false);

  const ITEMS_PER_PAGE = 50;

  const adminLevel = 'admin';
  const privilege = adminLevel === 'admin';

  useEffect(() => {
    const getData = () => {
      getAllPodcasts();
      getAllCategories();
      getAllChannels();
    };
    getData();
  }, []);

  const tableData = useMemo(() => {
    let computedPodcasts = podcasts;

    if (search) {
      computedPodcasts = computedPodcasts.filter((data) =>
        data.title.toLowerCase().includes(search.toLowerCase())
      );
    }

    setTotalItems(computedPodcasts.length);

    //Sorting podcasts
    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedPodcasts = computedPodcasts.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedPodcasts.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [podcasts, currentPage, search, sorting]);

  const getAllPodcasts = async (e) => {
    //e.preventDefault();
    const response = await axios.get(`${config.apiUrl}/api/admin/podcasts`);
    setPodcasts(response.data);
  };

  const fetchFromSpotify = async () => {
    const response = await axios.get(
      `${config.apiUrl}/api/admin/podcasts/refresh`
    );
    setPodcasts(response.data);
  };

  const setPodcastVisibility = async (id, visibility) => {
    axios.post(`${config.apiUrl}/api/admin/podcasts/${id}/${visibility}`);
  };

  const setFeaturedFlag = async (id, status) => {
    await axios.post(
      `${config.apiUrl}/api/admin/podcasts/${id}/feature/${status}`
    );
  };

  const setHomeFlag = async (id, status) => {
    await axios.post(
      `${config.apiUrl}/api/admin/podcasts/${id}/home/${status}`
    );
  };

  const getAllCategories = async () => {
    const response = await axios.get(`${config.apiUrl}/api/labels/categories`);
    let result = [];
    response.data.forEach((data) => {
      result.push({ value: data.title, label: data.title });
    });
    setCategories(result);
  };

  const getAllChannels = async () => {
    const response = await axios.get(`${config.apiUrl}/api/labels/channels`);
    let result = [];
    response.data.forEach((data) => {
      result.push({ value: data.title, label: data.title });
    });
    setChannels(result);
  };

  const handleCategory = async (id, category) => {
    await axios.post(
      `${config.apiUrl}/api/admin/podcasts/${id}/category/${category}`
    );
  };

  const handleChannel = async (id, channel) => {
    await axios.post(
      `${config.apiUrl}/api/admin/podcasts/${id}/channel/${channel}`
    );
  };

  return (
    <>
      <div className="managerContainer">
        <h2>Podcast Manager</h2>
        <div>Admin Level is: {adminLevel}</div>
        <br />
        <Button onClick={getAllPodcasts}>Reload</Button>
        <Button onClick={fetchFromSpotify}>
          Fetch New Podcasts From Spotify
        </Button>
        <br />
        <br />
        <div className="row w-100">
          <div className="col mb-3 col-12 text-center">
            <div className="row">
              <div className="col-md-6">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
              <div className="col-md-6 d-flex flex-row-reverse">
                <Search
                  onSearch={(value) => {
                    setSearch(value);
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>
            <br />

            <table className="table table-striped">
              <TableHeader
                headers={Columns}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <tbody>
                {tableData.map((result, i) => (
                  <tr key={result._id} className="tableRow">
                    <th
                      className="columnTitle"
                      width="400px"
                      scope="row"
                      onClick={() => {
                        setEditor(result);
                        setModal(true);
                      }}
                    >
                      {result.title}
                    </th>
                    <td>
                      {result.publishTime
                        ? format(new Date(result.publishTime), 'MM/dd/yyyy')
                        : null}
                    </td>
                    <td width="150px">{timeFormat(result.duration)}</td>
                    <td width="200px">
                      <Category
                        privilege={privilege}
                        id={result._id}
                        initial={result.category}
                        category={categories}
                        handleCategory={(e) => {
                          handleCategory(result._id, e.value);
                          tableData[i].category = e.value;
                        }}
                      />
                    </td>
                    <td width="200px">
                      <Channel
                        privilege={privilege}
                        id={result._id}
                        initial={result.channel}
                        channel={channels}
                        handleChannel={(e) => {
                          handleChannel(result._id, e.value);
                          tableData[i].channel = e.value;
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        defaultChecked={result.isVisible}
                        onChange={() => {
                          setPodcastVisibility(result._id, !result.isVisible);
                          tableData[i].isVisible = !result.isVisible;
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        defaultChecked={result.isFeatured}
                        onChange={() => {
                          setFeaturedFlag(result._id, !result.isFeatured);
                          tableData[i].isFeatured = !result.isFeatured;
                        }}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        defaultChecked={result.onHome}
                        onChange={() => {
                          setHomeFlag(result._id, !result.onHome);
                          tableData[i].onHome = !result.onHome;
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {editor ? (
          <div className="editor">
            <Modal open={modal} toggle={() => setModal(!modal)}>
              <ModalHeader>Podcasts Editor</ModalHeader>
              <ModalBody>
                <Editor data={editor} type="podcasts" />
              </ModalBody>
            </Modal>
          </div>
        ) : null}
      </div>
    </>
  );
};

function timeFormat(s) {
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;

  var hrs = '';
  if (s - mins > 0) {
    hrs = (s - mins) / 60;
    hrs = hrs + 'hr ';
  }
  return hrs + mins + 'min ' + secs + 'sec';
}

export default PodcastManager;
