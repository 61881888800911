import { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import EventTable from './EventTable';
import EventForm from './EventForm';
import moment from 'moment';
import axios from 'axios';
import config from '../../config';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const CalendarManager = (props) => {
  const [events, setEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState({});
  const getEvents = async () => {
    const response = await axios.get(`${config.apiUrl}/api/events`);
    console.log(response);
    const eventsArray = [];
    for (let event of response.data) {
      const title = event.claimedBy ? event.claimedBy : event.city;
      eventsArray.push({
        title,
        start: moment(event.eventStartDTO).toDate(),
        end: moment(event.eventEndDTO).toDate(),
        state: event.state,
        address: event.address,
        zipcode: event.zipcode,
      });
    }
    setEvents(eventsArray);
    console.log(events);
  };

  useEffect(() => {
    getEvents();
  }, []);

  const displayEvent = (event) => {
    setCurrentEvent(event);
  };


  return (
    <div>
      <EventForm onSubmit={getEvents}/>
      <Calendar
        localizer={localizer}
        events={events}
        onSelectEvent={(event) => {
          displayEvent(event);
        }}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
      <br />
      <br />
      <EventTable
        state={currentEvent.state}
        zipcode={currentEvent.zipcode}
        claimedBy={currentEvent.claimedBy}
      ></EventTable>
    </div>
  );
};

export default CalendarManager;
