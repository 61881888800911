import { Button, Form, FormInput, FormGroup } from 'shards-react';
import { useState } from 'react';
import { USER_ROLES } from '../constants';
import axios from 'axios';
import config from '../config';

const CustomFormGroup = ({ labelText, value, stateHandler }) => (
  <FormGroup>
    <label htmlFor={`#${value}`}>{labelText}</label>
    <FormInput
      value={value}
      onChange={(e) => {
        e.preventDefault();
        stateHandler(e.target.value);
      }}
      id={value}
      placeholder={value}
    />
  </FormGroup>
);

const CustomerCalendarForms = ({userRole, event}) => {
  const [firstName, setFirstName] = useState(undefined);
  const [lastName, setLastName] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [displayName, setDisplayName] = useState(undefined);
  const [phone, setPhone] = useState(undefined);
  const [zipcode, setZipcode] = useState(undefined);
  const [city, setCity] = useState(undefined);
  const [state, setState] = useState(undefined);
  const [address, setAddress] = useState(undefined);
  const [companyName, setCompanyName] = useState(undefined);
  const [jobTitle, setJobTitle] = useState(undefined);
  const [companyUrl, setCompanyUrl] = useState(undefined);

  const submitApplication = async (e) => {
    e.preventDefault();
    console.log(event)
    const body = {
      role: userRole,
      event,
      formData: {
        firstName,
        lastName,
        email,
        displayName,
        phone,
        zipcode,
        city,
        state,
        address,
        companyName,
        jobTitle,
        companyUrl
      },
      status: 'Pending',
    }

    const res = await axios.post(
      `${config.apiUrl}/api/application`,
      body
    );
    console.log(res);
  };

  return (
    <>
      <Form>
        {userRole === USER_ROLES.REGISTERED_USER ? (
          <>
            <CustomFormGroup
              labelText="First Name"
              value={firstName}
              stateHandler={setFirstName}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Last Name"
              value={lastName}
              stateHandler={setLastName}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Email"
              value={email}
              stateHandler={setEmail}
            ></CustomFormGroup>
          </>
        ) : null}

        {userRole === USER_ROLES.CONTENT_CONTRIBUTOR ? (
          <>
            <CustomFormGroup
              labelText="Display Name"
              value={displayName}
              stateHandler={setDisplayName}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Phone"
              value={phone}
              stateHandler={setPhone}
            ></CustomFormGroup>
          </>
        ) : null}

        {userRole === USER_ROLES.VOLUNTEER ? (
          <>
            <CustomFormGroup
              labelText="First Name"
              value={firstName}
              stateHandler={setFirstName}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Last Name"
              value={lastName}
              stateHandler={setLastName}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Email"
              value={email}
              stateHandler={setEmail}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Phone"
              value={phone}
              stateHandler={setPhone}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Zip Code"
              value={zipcode}
              stateHandler={setZipcode}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="City"
              value={city}
              stateHandler={setCity}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="State"
              value={state}
              stateHandler={setState}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Address"
              value={address}
              stateHandler={setAddress}
            ></CustomFormGroup>
          </>
        ) : null}

        {/* todo: days of week available */}
        {/* todo: preferred time */}

        {userRole === USER_ROLES.EVENT_HOST ? (
          <>
            <CustomFormGroup
              labelText="First Name"
              value={firstName}
              stateHandler={setFirstName}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Last Name"
              value={lastName}
              stateHandler={setLastName}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Company Name"
              value={companyName}
              stateHandler={setCompanyName}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Job Title"
              value={jobTitle}
              stateHandler={setJobTitle}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Company Url"
              value={companyUrl}
              stateHandler={setCompanyUrl}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Email"
              value={email}
              stateHandler={setEmail}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Phone"
              value={phone}
              stateHandler={setPhone}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Zip Code"
              value={zipcode}
              stateHandler={setZipcode}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="City"
              value={city}
              stateHandler={setCity}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="State"
              value={state}
              stateHandler={setState}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Address"
              value={address}
              stateHandler={setAddress}
            ></CustomFormGroup>
          </>
        ) : null}

        {userRole === USER_ROLES.SPONSOR ? (
          <>
            {' '}
            <CustomFormGroup
              labelText="Company Name"
              value={companyName}
              stateHandler={setCompanyName}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Company Url"
              value={companyUrl}
              stateHandler={setCompanyUrl}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Email"
              value={email}
              stateHandler={setEmail}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Phone"
              value={phone}
              stateHandler={setPhone}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Zip Code"
              value={zipcode}
              stateHandler={setZipcode}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="City"
              value={city}
              stateHandler={setCity}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="State"
              value={state}
              stateHandler={setState}
            ></CustomFormGroup>
            <CustomFormGroup
              labelText="Address"
              value={address}
              stateHandler={setAddress}
            ></CustomFormGroup>
          </>
        ) : null}
      </Form>
      <Button onClick={submitApplication}>Submit Application</Button>
    </>
  );
};

export default CustomerCalendarForms;
