import React from 'react';
import { useGoogleLogin } from 'react-google-login';
import google from './google.png';
import './GoogleButton.scss';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import config from '../config';

const clientId =
  '793435657926-jj8sdq68gt8orkajr489eoolam4iet74.apps.googleusercontent.com';

function GoogleButton() {
  const history = useHistory();

  const onFailure = (res) => {
    console.log('Login failed: res:', res);
  };

  const onSuccess = async (res) => {
    const user = res.profileObj;
    user.idToken = res.tokenObj.id_token;
    const response = await axios.post(
      `${config.apiUrl}/api/auth/googleSignup`,
      user
    );
    console.log(response);
    if (response.status === 200) {
      history.push('/');
      history.go(0)
    }
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    accessType: 'offline',
  });

  return (
    <div className="login-form-container">
      <button type="button" onClick={signIn} className="icon-button">
        <img src={google} alt="google login" className="icon" />
      </button>
    </div>
  );
}

export default GoogleButton;
