import axios from 'axios';
import React, {Component} from "react";


class UpdateVolunteerInfo extends Component {
    constructor (props) {
        super(props)
        this.state = {
            volunteerId: this.props.volunteer.volunteerId,
            name: this.props.volunteer.name,
            email: this.props.volunteer.email,
            phone: this.props.volunteer.phone,
            startDate:this.props.volunteer.startDate,
            endDate: this.props.volunteer.endDate,
            secondaryEmail: this.props.volunteer.secondaryEmail
        }
        console.log(this.props.volunteer)
    }

    setUserRequest = async (e) => {
        e.preventDefault();
        const info = {
            volunteerId: this.state.volunteerId,
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            startDate:this.state.startDate,
            endDate: this.state.endDate,
            secondaryEmail: this.state.secondaryEmail
        }
        console.log(info)

        if (window.confirm('Are you sure you make this modification?')){
          const response = await axios.patch(
              `${this.props.config.apiUrl}/api/admin/volunteers/` + this.state.volunteerId, 
              info );
          console.log(response.data);
        } 
        
    }

    render(){
        return(
            <div>
                <form onSubmit={this.setUserRequest}>
                    <label>
                        Name: 
                        <input type="text" value={this.state.name} onChange={(e)=>{
                                e.preventDefault(); 
                                this.setState({name: e.target.value})}
                                } />
                    </label>
                    <br/>
                    <label>
                        Email: 
                        <input type="text" value={this.state.email} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({email: e.target.value})}
                            } />
                    </label>
                    <br/>
                    <label>
                        Secondary Email: 
                        <input type="text" value={this.state.secondaryEmail} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({secondaryEmail: e.target.value})}
                            } />
                    </label>
                    <br/>
                    <label>
                        Phone: 
                        <input type="text" value={this.state.phone} onChange={(e)=>{
                                e.preventDefault(); 
                                this.setState({phone: e.target.value})}
                                } />
                    </label>
                    <br/>
                    <label>
                        Start Date: (mm/dd/yy)
                        <input type="text" style={{width:250}} value={this.state.startDate} onChange={(e)=>{
                                e.preventDefault(); 
                                this.setState({startDate: e.target.value})}
                                } />
                    </label>
                    <br/>
                    <label>
                        End Date: (mm/dd/yy)
                        <input type="text" style={{width:250}}  value={this.state.endDate} onChange={(e)=>{
                                e.preventDefault(); 
                                this.setState({endDate: e.target.value})}
                                } />
                    </label>

                    <input type="submit" value="Submit" />

                </form>
            </div>
        );
    }

}


export default UpdateVolunteerInfo;
