import config from '../config';
import axios from 'axios';
import { useState, useEffect } from 'react';
import './profileStyle.scss';

const StrategicPartnerTable = () => {
    const [strategicPartnerEmail, setStrategicPartnerEmail] = useState('');
    const [strategicPartnerPhone, setStrategicPartnerPhone] = useState('');
    const [strategicPartnerZipCode, setStrategicPartnerZipCode] = useState('');
    const [strategicPartnerCity, setStrategicPartnerCity] = useState('');
    const [strategicPartnerState, setStrategicPartnerState] = useState('');
    const [strategicPartnerAddress, setStrategicPartnerAddress] = useState('');
    const [strategicPartnerContactName, setStrategicPartnerContactName] = useState('');
    const [strategicPartnerContactJob, setStrategicPartnerContactJob] = useState('');
    const [strategicPartnerCompany, setStrategicPartnerCompany] = useState('');
    const [strategicPartnerCompanyUrl, setStrategicPartnerCompanyUrl] = useState('');
    const [strategicPartnerCategory, setStrategicPartnerCategory] = useState('');
    const [strategicPartnerStartDate, setStrategicPartnerStartDate] = useState('');
    const [strategicPartnerEndDate, setStrategicPartnerEndDate] = useState('');

    const getStrategicPartnerInfo = async () => {
        const res = await axios.get(`${config.apiUrl}/api/user/strategicPartnerInfo`);
        console.log(res);
        if (res.data.strategicPartner === 1){
            setStrategicPartnerEmail(res.data.email);
            setStrategicPartnerPhone(res.data.phone);
            setStrategicPartnerZipCode(res.data.zipCode);
            setStrategicPartnerCity(res.data.city);
            setStrategicPartnerState(res.data.state);
            setStrategicPartnerAddress(res.data.address);
            setStrategicPartnerContactName(res.data.contactName);
            setStrategicPartnerContactJob(res.data.contactJob);
            setStrategicPartnerCompany(res.data.company);
            setStrategicPartnerCompanyUrl(res.data.companyUrl);
            setStrategicPartnerCategory(res.data.category);
            setStrategicPartnerStartDate(res.data.startDate);
            setStrategicPartnerEndDate(res.data.endDate);
        }
    };

    useEffect(() => {
        getStrategicPartnerInfo();
      }, []);

    const updateStrategicPartnerInfo = async(e) => {
        e.preventDefault();
        let info = {strategicPartnerEmail, strategicPartnerPhone, strategicPartnerZipCode, 
            strategicPartnerCity, strategicPartnerState, strategicPartnerAddress, strategicPartnerCompany, strategicPartnerCompanyUrl, 
            strategicPartnerContactName, strategicPartnerContactJob, strategicPartnerCategory, strategicPartnerStartDate, strategicPartnerEndDate};
        const res = await axios.patch(`${config.apiUrl}/api/user/strategicPartnerInfo`, info);
        console.log(res);
    }

    let StrategicPartnerTable = (        
        <div class="flexbox-container">
            <div className="update user info">
                <h3>Strategic Partner Profile Table</h3>
                <form onSubmit={updateStrategicPartnerInfo}>
                    <label>
                        Email: 
                        <input type="text" value={strategicPartnerEmail} onChange={(e)=>{
                            e.preventDefault(); 
                            setStrategicPartnerEmail(e.target.value)}
                            } />
                    </label>
                    <br></br>
    
                    <label>
                        Phone: 
                        <input type="text" value={strategicPartnerPhone} defaultValue={''} onChange={(e)=>{
                            e.preventDefault(); 
                            setStrategicPartnerPhone(e.target.value)}
                            } />
                    </label>
                    <br></br>
            
                    <label>
                        Zip Code: 
                        <input type="number" value={strategicPartnerZipCode} onChange={(e)=>{
                            e.preventDefault(); 
                            setStrategicPartnerZipCode(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        City: 
                        <input type="text" value={strategicPartnerCity} onChange={(e)=>{
                            e.preventDefault(); 
                            setStrategicPartnerCity(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        State: 
                        <input type="text" value={strategicPartnerState} onChange={(e)=>{
                            e.preventDefault(); 
                            setStrategicPartnerState(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Address: 
                        <input type="text" value={strategicPartnerAddress} onChange={(e)=>{
                            e.preventDefault(); 
                            setStrategicPartnerAddress(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Contact Name: 
                        <input type="text" value={strategicPartnerContactName} onChange={(e)=>{
                            e.preventDefault(); 
                            setStrategicPartnerContactName(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Contact Job: 
                        <input type="text" value={strategicPartnerContactJob} onChange={(e)=>{
                            e.preventDefault(); 
                            setStrategicPartnerContactJob(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Company: 
                        <input type="text" value={strategicPartnerCompany} onChange={(e)=>{
                            e.preventDefault(); 
                            setStrategicPartnerCompany(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Company URL: 
                        <input type="text" value={strategicPartnerCompanyUrl} onChange={(e)=>{
                            e.preventDefault(); 
                            setStrategicPartnerCompanyUrl(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Category: 
                        <input type="text" value={strategicPartnerCategory} onChange={(e)=>{
                            e.preventDefault(); 
                            setStrategicPartnerCategory(e.target.value)}
                            } />
                    </label>
                    <label>
                        Start Date: 
                        <input type="text" value={strategicPartnerStartDate} onChange={(e)=>{
                            e.preventDefault(); 
                            setStrategicPartnerStartDate(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        End Date: 
                        <input type="text" value={strategicPartnerEndDate} onChange={(e)=>{
                            e.preventDefault(); 
                            setStrategicPartnerEndDate(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <input type='submit' value='modify strategic partner profile info'/>
                    <br></br>
                    
                </form>
            </div>
        </div>)

        return StrategicPartnerTable;
}

export default StrategicPartnerTable;