import axios from 'axios';
import React, {Component} from "react";


class UpdateSponsorInfo extends Component {
    constructor (props) {
        super(props)
        this.state = {
            sponsorId: this.props.sponsor.sponsorId,
            email: this.props.sponsor.email,
            phone: this.props.sponsor.phone,
            zipCode: this.props.sponsor.zipCode,
            city: this.props.sponsor.city,
            state: this.props.sponsor.state,
            address: this.props.sponsor.address,
            company: this.props.sponsor.company,
            companyUrl: this.props.sponsor.companyUrl,
            sponsorLevel: this.props.sponsor.sponsorLevel,
            sponsorshipStartDate: this.props.sponsor.sponsorshipStartDate,
            sponsorshipExpirationDate: this.props.sponsor.sponsorshipExpirationDate,
            contactName: this.props.sponsor.contactName,
            contactJob: this.props.sponsor.contactJob,
            attnBillingName: this.props.sponsor.attnBillingName,
            paidInGoodStanding: this.props.sponsor.paidInGoodStanding,
            currentlyActive: this.props.sponsor.currentlyActive,
            interestedInRenewing: this.props.sponsor.interestedInRenewing,
            specialInstructionsComments: this.props.sponsor.specialInstructionsComments
        }
        console.log(this.props.sponsor)
    }

    setUserRequest = async (e) => {
        e.preventDefault();
        const info = {
            ...this.state
        }
        console.log(info)

        if (window.confirm('Are you sure you make this modification?')){
          const response = await axios.patch(
              `${this.props.config.apiUrl}/api/admin/letsfika_sponsors/` + this.state.sponsorId, 
              {info} );
          console.log(response.data);
        } 
        
    }

    
    render(){
        return(
            <div>
                <form onSubmit={this.setUserRequest}>
                    <label>
                        Company Name(required): 
                        <input type="text" value={this.state.company} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({company: e.target.value})}
                        } />
                    </label>
                    <label>
                        Paid / In Good Standing: 
                        <input type="text" value={this.state.paidInGoodStanding} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({paidInGoodStanding: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Company Url(required): 
                        <input type="text" value={this.state.companyUrl} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({companyUrl: e.target.value})}
                        } />
                    </label>
                    <label>
                        Currently Active: 
                        <input type="text" value={this.state.currentlyActive} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({currentlyActive: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Sponsorship level: 
                        <input type="text" value={this.state.sponsorLevel} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({sponsorLevel: e.target.value})}
                        } />
                    </label>
                    <label>
                        Interested In Renewing: 
                        <input type="text" value={this.state.interestedInRenewing} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({interestedInRenewing: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Sponsorship Start Date: 
                        <input type="text" value={this.state.sponsorshipStartDate} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({sponsorshipStartDate: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Sponsorship Expiration Date: 
                        <input type="text" value={this.state.sponsorshipExpirationDate} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({sponsorshipExpirationDate: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Key Contact Name: 
                        <input type="text" value={this.state.contactName} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({contactName: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Key Contact Job Title: 
                        <input type="text" value={this.state.contactJob} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({contactJob: e.target.value})}
                        } />
                    </label>
                    <br/>
                    
                    <label>
                        Email(required): 
                        <input type="text" value={this.state.email} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({email: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Phone: 
                        <input type="text" value={this.state.phone} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({phone: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Billing Address: 
                        <input type="text" value={this.state.address} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({address: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        City: 
                        <input type="text" value={this.state.city} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({city: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        State: 
                        <input type="text" value={this.state.state} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({state: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Zipcode: 
                        <input type="text" value={this.state.zipCode} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({zipCode: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Special Instructions / Comments: 
                        <input type="text" value={this.state.specialInstructionsComments} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({specialInstructionsComments: e.target.value})}
                        } />
                    </label>
                    <br/>

                    

                    <input type="submit" value="Submit" />

                </form>
            </div>
        );
    }


}


export default UpdateSponsorInfo;

