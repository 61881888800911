import config from './../config';
import axios from 'axios';
import { FormTextarea, Button, FormGroup, Form } from 'shards-react';
import React, { useEffect, useState } from 'react';
import './manager.scss';

const DisplayManager = () => {
  const [displayContent, setDisplayContent] = useState([]);

  const adminLevel = 'admin';
  // eslint-disable-next-line no-unused-vars
  const privilege = adminLevel === 'admin';

  useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    const response = await axios.get(`${config.apiUrl}/api/display`);
    console.log(response.data);
    setDisplayContent(response.data);
  };

  const updateContent = async (e, page, type, text) => {
    e.preventDefault();
    try {
      const response = await axios({
        url: `${config.apiUrl}/api/admin/display`,
        method: 'post',
        params: {
          type: type,
          text: text,
          page: page,
        },
      });
      console.log(response.data);
    } catch (err) {
      console.log('error');
    }
  };

  return (
    <>
      <div className="display-manager-container">
        <br />
        <h2>Display Manager</h2>
        <div>Admin Level is: {adminLevel}</div>
        <br />
        <br />
        <h3>Home Page</h3> <br />
        {displayContent.map((item, i) => {
          if (item.page === 'home') {
            var changedText = item.text;
            return (
              <Form
                id={'home' + i}
                onSubmit={(e) => {
                  console.log('clicked');
                  updateContent(e, 'home', item.type, changedText);
                }}
              >
                <FormGroup>
                  <label>
                    <h4>{item.type}</h4>
                  </label>
                  <FormTextarea
                    defaultValue={item.text}
                    onChange={(e) => {
                      changedText = e.target.value;
                    }}
                  />
                  <br />
                  <Button
                    type="submit"
                    className="btn btn-secondary float-right"
                  >
                    Submit
                  </Button>
                </FormGroup>
                <br />
              </Form>
            );
          } else {
            return null;
          }
        })}
        <br />
        <br />
        <h3>Listen Page</h3>
        <br />
        {displayContent.map((item, i) => {
          if (item.page === 'listen') {
            var changedText = item.text;
            return (
              <Form
                id={'listen' + i}
                onSubmit={(e) => {
                  updateContent(e, 'listen', item.type, changedText);
                }}
              >
                <FormGroup>
                  <label>
                    <h4>{item.type}</h4>
                  </label>
                  <FormTextarea
                    defaultValue={item.text}
                    onChange={(e) => {
                      changedText = e.target.value;
                    }}
                  />
                  <br />
                  <Button
                    type="submit"
                    className="btn btn-secondary float-right"
                  >
                    Submit
                  </Button>
                </FormGroup>
                <br />
              </Form>
            );
          } else {
            return null;
          }
        })}
        <br />
        <br />
        <h3>Watch Page</h3>
        <br />
        {displayContent.map((item, i) => {
          if (item.page === 'watch') {
            var changedText = item.text;
            return (
              <Form
                id={'watch' + i}
                onSubmit={(e) => {
                  updateContent(e, 'watch', item.type, changedText);
                }}
              >
                <FormGroup>
                  <label>
                    <h4>{item.type}</h4>
                  </label>
                  <FormTextarea
                    defaultValue={item.text}
                    onChange={(e) => {
                      changedText = e.target.value;
                    }}
                  />
                  <br />
                  <Button
                    type="submit"
                    className="btn btn-secondary float-right"
                  >
                    Submit
                  </Button>
                </FormGroup>
                <br />
              </Form>
            );
          } else {
            return null;
          }
        })}
        <br />
        <br />
        <h3>Read Page</h3>
        <br />
        {displayContent.map((item, i) => {
          if (item.page === 'read') {
            var changedText = item.text;
            return (
              <Form
                id={'read' + i}
                onSubmit={(e) => {
                  updateContent(e, 'read', item.type, changedText);
                }}
              >
                <FormGroup>
                  <label>
                    <h4>{item.type}</h4>
                  </label>
                  <FormTextarea
                    defaultValue={item.text}
                    onChange={(e) => {
                      changedText = e.target.value;
                    }}
                  />
                  <br />
                  <Button
                    type="submit"
                    className="btn btn-secondary float-right"
                  >
                    Submit
                  </Button>
                </FormGroup>
                <br />
              </Form>
            );
          } else {
            return null;
          }
        })}
        <br />
        <br />
      </div>
    </>
  );
};

export default DisplayManager;
