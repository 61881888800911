const SortOptions = [
  {
    value: '-1',
    label: 'Newest',
  },
  {
    value: '1',
    label: 'Oldest',
  },
];

export default SortOptions;
