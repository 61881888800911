import config from '../config';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { useState, useEffect } from 'react';
import BillingInfo from './BillingInfo.js';
import VolunteerRecords from './VolunteerRecords.js';
import Favorites from '../favorites/favorites.js';
import './profileStyle.scss';
import VolunteerTable from './VolunteerProfile';
import EventPartnerTable from './EventPartnerProfile';
import SponsorTable from './SponsorProfile';
import StrategicPartnerTable from './StrategicPartnerProfile';
import { Button } from 'shards-react';


const UserProfile = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [secondaryEmail, setSecondaryEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [userName, setUserName] = useState('');

    const [favoriteVideos, setFavoriteVideos] = useState([]);
    const [favoritePodcasts, setFavoritePodcasts] = useState([]);
    const [favoriteBlogs, setFavoriteBlogs] = useState([]);

    const [showFavorites, setShowFavorites] = useState('');
    const [showVolunteerProfile, setShowVolunteerProfile] = useState('');
    const [showEventPartnerProfile, setShowEventPartnerProfile] = useState('');
    const [showStrategicPartnerProfile, setShowStrategicPartnerProfile] = useState('');
    const [showSponsorProfile, setShowSponsorProfile] = useState('');

    const getFavorites = async () => {
        const res = await axios.get(`${config.apiUrl}/api/favorites`);
        console.log(res);
        setFavoriteVideos(res.data.favoriteVideos);
        setFavoritePodcasts(res.data.favoritePodcasts);
        setFavoriteBlogs(res.data.favoriteBlogs);
    };
  
    const getUserInfo = async () => {
        const res = await axios.get(`${config.apiUrl}/api/user/userInfo`);
        console.log(res);
        setName(res.data.name);
        setEmail(res.data.email);
        setSecondaryEmail(res.data.secondaryEmail);
        setPhone(res.data.phone);
        setUserName(res.data.userName);
    };

    const getVolunteerInfo = async () => {
        const res = await axios.get(`${config.apiUrl}/api/user/volunteerInfo`);
        console.log(res);
        if (res.data.volunteer === 1){
            setShowVolunteerProfile(true);
        }
    };

    const getEventPartnerInfo = async () => {
        const res = await axios.get(`${config.apiUrl}/api/user/eventPartnerInfo`);
        console.log(res);
        if (res.data.eventPartner === 1){
            setShowEventPartnerProfile(true);
        }
    };

    const getStrategicPartnerInfo = async () => {
        const res = await axios.get(`${config.apiUrl}/api/user/strategicPartnerInfo`);
        console.log(res);
        if (res.data.strategicPartner === 1){
            setShowStrategicPartnerProfile(true);
        }
    };

    const getSponsorInfo = async () => {
        const res = await axios.get(`${config.apiUrl}/api/user/sponsorInfo`);
        console.log(res);
        if (res.data.sponsor === 1){
            setShowSponsorProfile(true);
        }
    };
  
    useEffect(() => {
      getUserInfo();
    //   getFavorites();
    //   getVolunteerInfo();
    //   getEventPartnerInfo();
    //   getSponsorInfo();
    //   getStrategicPartnerInfo();
    }, []);

    const updateInfo = async (e) => {
        // TODO: update user info
        e.preventDefault();
        console.log(name)
        console.log(email)
        console.log(phone)
        console.log(userName)
        let info = {name, phone, userName, email, secondaryEmail};
        const res = await axios.patch(`${config.apiUrl}/api/user/userInfo`, info);
        console.log(res);
    }


    return (
        <div class="flexbox-container">
            <div className="update user info">
            <h3>User Profile Table</h3>
                <form onSubmit={updateInfo}>
                    <label >
                        Email: 
                        <input type="text" style={ {width:'250px'} } value={email} onChange={(e)=>{
                            e.preventDefault(); 
                            setEmail(e.target.value)}
                            } />
                    </label>
                    <br></br>

                    <label>
                        Name: 
                        <input type="text" value={name} onChange={(e)=>{
                            e.preventDefault(); 
                            setName(e.target.value)}
                            } />
                    </label>
                    <br></br>

                    <label>
                        Username: 
                        <input type="text" value={userName} onChange={(e)=>{
                            e.preventDefault(); 
                            setUserName(e.target.value)}
                            } />
                    </label>
                    <br></br>

                    <label>
                        Phone: 
                        <input type="text" value={phone} defaultValue={''} onChange={(e)=>{
                            e.preventDefault(); 
                            setPhone(e.target.value)}
                            } />
                    </label>
                    <br></br>

                   
                    <input type='submit' value='modify profile info'/>
                    <br></br>

                    
                </form>
            </div>
            <div>
                <Button onClick = {() => {
                    setShowFavorites(true);
                    setShowVolunteerProfile(false);
                    setShowEventPartnerProfile(false);
                    setShowStrategicPartnerProfile(false);
                    setShowSponsorProfile(false);
                    }}> Favorites
                </Button>
                <br/>
                <Button onClick = {() => {
                    setShowFavorites(false);
                    setShowVolunteerProfile(true);
                    setShowEventPartnerProfile(false);
                    setShowStrategicPartnerProfile(false);
                    setShowSponsorProfile(false);
                    }}> Volunteer Profile
                </Button>
                <br/>
                <Button onClick = {() => {
                    setShowFavorites(false);
                    setShowVolunteerProfile(false);
                    setShowEventPartnerProfile(true);
                    setShowStrategicPartnerProfile(false);
                    setShowSponsorProfile(false);
                    }}> Event Partner Profile
                </Button>
                <br/>
                <Button onClick = {() => {
                    setShowFavorites(false);
                    setShowVolunteerProfile(false);
                    setShowEventPartnerProfile(false);
                    setShowStrategicPartnerProfile(true);
                    setShowSponsorProfile(false);
                    }}> Strategic Partner Profile
                </Button>
                <br/>
                <Button onClick = {() => {
                    setShowFavorites(false);
                    setShowVolunteerProfile(false);
                    setShowEventPartnerProfile(false);
                    setShowStrategicPartnerProfile(false);
                    setShowSponsorProfile(true);
                    }}> Sponsor Profile
                </Button>
                <br/>


            </div>
            

            <div>
                {showFavorites===true ? <Favorites 
                    favoriteVideos={favoriteVideos}
                    favoritePodcasts={favoritePodcasts}
                    favoriteBlogs={favoriteBlogs} 
                ></Favorites> : '' }

                {showVolunteerProfile===true ? <VolunteerTable></VolunteerTable>: ''}
           
                {showSponsorProfile===true ? <SponsorTable></SponsorTable>: ''}
           
                {showEventPartnerProfile===true ? <EventPartnerTable></EventPartnerTable>: ''}
       
                {showStrategicPartnerProfile===true ? <StrategicPartnerTable></StrategicPartnerTable>: ''}
            </div>
          
        </div>
      );

}


export default UserProfile;