import { Component } from "react";



export default class BillingInfo extends Component {

    render(){
        return(
            <div>
                <label>TODO: Some Billing Info</label>
            </div>
        );
    }
}