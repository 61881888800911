import { useState } from 'react';
import { Form, FormInput, FormSelect, FormGroup, Button } from 'shards-react';
import DatePicker from 'react-datepicker';
import { STATES } from '../../constants';
import axios from 'axios';
import config from '../../config';

const EventForm = ({ onSubmit }) => {
  const [state, setState] = useState('California');
  const [city, setCity] = useState(undefined);
  const [zipcode, setZipcode] = useState(undefined);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const submitForm = async () => {
    const res = await axios.post(`${config.apiUrl}/api/events`, {
      state,
      zipcode,
      city,
      eventStartDTO: startDate,
      eventEndDTO: endDate,
    });
    console.log(res);
    onSubmit();
  };

  const isRequiredFieldsFilled = state && zipcode && startDate && endDate && city;

  return (
    <>
    <h1>Calendar</h1>
    <h4>Where will the Fika team be and when?</h4>
    <Form>
      <FormGroup>
        <label htmlFor="#state">State</label>
        <FormSelect
          onChange={(e) => {
            setState(e.target.value);
          }}
        >
          {STATES.map((state) => (
            <option value={state}>{state}</option>
          ))}
        </FormSelect>
      </FormGroup>
      <FormGroup>
        <label htmlFor="#city">City</label>
        <FormInput
          onChange={(e) => {
            setCity(e.target.value);
          }}
          id="City"
          placeholder="City"
        />
      </FormGroup>
      <FormGroup>
        <label htmlFor="#zipcode">Zipcode</label>
        <FormInput
          onChange={(e) => {
            setZipcode(e.target.value);
          }}
          id="Zipcode"
          placeholder="Zipcode"
        />
      </FormGroup>
      <label htmlFor="#start-date">Start Date</label>
      <br />
      <DatePicker
        id="start-date"
        selected={startDate}
        onChange={(date) => setStartDate(date)}
      />
      <br />
      <br />
      <label htmlFor="#end-date">End Date</label>
      <br />
      <DatePicker
        id="end-date"
        selected={endDate}
        onChange={(date) => setEndDate(date)}
      />
      <br />
      <br />
      <Button disabled={!isRequiredFieldsFilled} onClick={submitForm}>
        Submit
      </Button>
    </Form>
    </>
  );
};

export default EventForm;
