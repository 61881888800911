import axios from 'axios';
import React, {Component} from "react";

class CreateEventPartner extends Component {
    constructor (props) {
        super(props)
        this.state = {
            name: '',
            company: '',
            companyUrl: '',

            job: '',
            email: '',
            phone: '',

            address: '',
            city: '',
            state: '',
            zipCode: '',
            
            preferedEventDate: '',
            preferedStartTime: '',
            preferedEndTime: '',
            freeParking: '',
        }
    }

    createEventPartner = async (e) => {
        e.preventDefault();
        const info = {...this.state}
        try{
            const res = await axios.post(`${this.props.config.apiUrl}/api/admin/letsfika_eventpartner`, {info})
            this.setState({
                name: '',
                company: '',
                companyUrl: '',
                job: '',
                email: '',
                phone: '',
                address: '',
                city: '',
                state: '',
                zipCode: '',
                preferedEventDate: '',
                preferedStartTime: '',
                preferedEndTime: '',
                freeParking: '',
            })
            console.log(res);
        } catch (e) {
            console.log(e);
        }
    }

    render(){
        return(
            <div>
                <label>Register New Event Partner below: </label>
                <br/>
                <form onSubmit={this.createEventPartner}>
                    <label>
                        Name(required): 
                        <input type="text" value={this.state.name} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({name: e.target.value})}
                        } />
                    </label>
                    <br/>
                    <label>
                        Company Name(required): 
                        <input type="text" value={this.state.company} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({company: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Company Url: 
                        <input type="text" value={this.state.companyUrl} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({companyUrl: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Job Title: 
                        <input type="text" value={this.state.job} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({job: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Prefered Event Date: 
                        <input type="text" value={this.state.preferedEventDate} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({preferedEventDate: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Prefered Start Date: 
                        <input type="text" value={this.state.preferedStartTime} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({preferedStartTime: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Prefered End Time: 
                        <input type="text" value={this.state.preferedEndTime} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({preferedEndTime: e.target.value})}
                        } />
                    </label>
                    <br/>
                    
                    <label>
                        Email(required): 
                        <input type="text" value={this.state.email} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({email: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Phone: 
                        <input type="text" value={this.state.phone} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({phone: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Address: 
                        <input type="text" value={this.state.address} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({address: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        City: 
                        <input type="text" value={this.state.city} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({city: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        State: 
                        <input type="text" value={this.state.state} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({state: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Zipcode: 
                        <input type="text" value={this.state.zipCode} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({zipCode: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Free Parking: 
                        <input type="text" value={this.state.freeParking} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({freeParking: e.target.value})}
                        } />
                    </label>
                    <br/>


                    <input type="submit" value="Submit" />

                </form>
            </div>
        );
    }
};

export default CreateEventPartner;