import React, { useState, useEffect } from 'react';
import { FormTextarea, Button, FormGroup, Form } from 'shards-react';
import { WithContext as ReactTags } from 'react-tag-input';
import config from '../config';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { createAlert } from '../common/AlertBannerSlice';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const Editor = (props) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [author, setAuthor] = useState();
  const [location, setLocation] = useState();
  const [tags, setTags] = useState([]);
  const [id, setId] = useState();

  useEffect(() => {
    setId(props.data._id);
    setLocation(props.data.location);
    setAuthor(props.data.author);
    setDescription(props.data.description);
    setTitle(props.data.title);
    renderTags(props.data.tags);
    console.log(props.type);
  }, [props.data, props.type]);

  const renderTags = (initialTags) => {
    let renderedTags = [];
    if (initialTags.length > 0) {
      renderedTags = initialTags.map((tag) => {
        return { id: tag, text: tag };
      });
    }
    setTags(renderedTags);
  };

  const handleDelete = (i) => {
    setTags(tags.filter((index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
    console.log('added tags' + tags);
  };

  const handleSubmit = async () => {
    let newTags = [];
    if (tags) {
      newTags = tags.map((tag) => {
        return tag.text;
      });
    }
    const param = {
      id: id,
      author: author !== props.data.author ? author : null,
      location: location !== props.data.location ? location : null,
      title: title !== props.data.title ? title : null,
      description: description !== props.data.description ? description : null,
      tags: newTags !== props.data.tags ? newTags : null,
    };

    if (
      param.author ||
      param.location ||
      param.title ||
      param.description ||
      param.tags.length > 0
    ) {
      try {
        const response = await axios({
          url: `${config.apiUrl}/api/admin/${props.type}/edit`,
          method: 'post',
          params: param,
        });
        if (response.status === 200) {
          dispatch(
            createAlert({
              message: 'Success! Your submission has been saved!',
              theme: 'success',
            })
          );
        }
      } catch (err) {
        dispatch(
          createAlert({
            message: err.response.data.error,
            theme: 'danger',
          })
        );
      }
    } else {
      dispatch(
        createAlert({
          message: 'No new changes.',
          theme: 'danger',
        })
      );
    }
  };

  return (
    <Form key={id}>
      <FormGroup>
        <label>Title:</label>
        <FormTextarea
          defaultValue={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <label>Author:</label>
        <FormTextarea
          defaultValue={author}
          onChange={(e) => {
            setAuthor(e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <label>Location:</label>
        <FormTextarea
          defaultValue={location}
          onChange={(e) => {
            setLocation(e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <div>
          <label>Description:</label>
        </div>
        <FormTextarea
          style={{ width: '100%', height: '200px' }}
          defaultValue={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <div>
          <label>Tags:</label>
        </div>
        <div>
          <ReactTags
            className={{ tag: 'tagClass' }}
            tags={tags}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            delimiters={delimiters}
            placeholder=""
          />
        </div>
      </FormGroup>
      <Button onClick={handleSubmit} className="btn btn-secondary float-right">
        Submit
      </Button>
    </Form>
  );
};

export default Editor;
