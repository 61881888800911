import config from '../config';
import axios from 'axios';
import { useEffect, useState, useMemo } from 'react';
import { Button } from 'shards-react';
import './manager.scss';
import { Pagination, Search } from '../components/table';
import UpdateSponsorInfo from './SponsorComponent/updateSponsorInfoComponent.js';
import CreateSponsor from './SponsorComponent/createSponsor.js';

const SponsorManager = () => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const ITEMS_PER_PAGE = 5;


    const [showCreateForm, setShowCreateForm] = useState(false);
    const [showUserTable, setShowUserTable] = useState(true);
    const [showUserDetailedInfo, setShowUserDetailedInfo] = useState(false);

    let createForm = (
      <div className="createSponsor">
        <br/>
        <Button onClick={() => {
          setShowCreateForm(false);
          setShowUserDetailedInfo(false);
          setShowUserTable(true);
          refreshTable();
        }}>
          Return
        </Button>
        <br/>
        <CreateSponsor config={config}></CreateSponsor> 
      </div>
    );

    useEffect(() => {
      getUsers();
      getAdminLevel();
    }, []);

    const [adminLevel, setAdminLevel] = useState('Loading...');
    
    const getAdminLevel = async () => {
        axios.get(`${config.apiUrl}/api/admin/adminlevel`)
      .then( (res)=>{
        console.log(res)
        setAdminLevel(map[res.data.adminLevel]);
      })
      .catch((e)=>{
        console.log(e);
      })
    }
  
    const tableData = useMemo(() => {
      let computedUsers = users;
  
      if (search) {
        computedUsers = computedUsers.filter((data) =>
          data.name.toLowerCase().includes(search.toLowerCase())
        );
      }
  
      setTotalItems(computedUsers.length);
  
      return computedUsers.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    }, [users, currentPage, search])
  
    const getUsers = async(e) => {
      // e.preventDefault();
      const response = await axios.get(`${config.apiUrl}/api/admin/letsfika_sponsors`);
      console.log(response.data);
      setUsers(response.data);
    }

    const getUser = async (n) => {
      const response = await axios.get(`${config.apiUrl}/api/admin/letsfika_sponsors/`+ n);
      console.log(response.data);
      console.log(n)
      setUser(response.data);
    };
  
    const refreshTable = getUsers;
  
    const deleteUser = async (sponsorId) => {
      const response = await axios.post(`${config.apiUrl}/api/admin/letsfika_sponsors/delete/${sponsorId}`);
      console.log(response.data);
      refreshTable();
    }
  
    const map = {
      0: 'Super Admin',
      1: 'Regular Admin',
      2: 'Editor',
      3: 'Normal User'
    }   
    
    let userForm = (
      <div>
        <br/>
        <Button onClick = {() => {
            setShowUserTable(true);
            setShowUserDetailedInfo(false);
            setShowCreateForm(false);
            refreshTable();
          }}>Return
        </Button>

        <br/>
        <br/>

        <UpdateSponsorInfo 
          sponsor={user}
          config={config}>
        </UpdateSponsorInfo>
      
      </div>
    );
  
    let userTable = '';
    if (tableData.length !== 0) {
      userTable = (
        <div>
          <br/>

          <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          <div className="col-md-6">
            <Search
              onSearch={(value) => {
                setSearch(value);
                setCurrentPage(1);
              }}
            />
          </div>

          <table>
            <thead>
              <tr>
                <td>Company Name</td>
                <td>Email(required)</td>
                <td>Phone</td>
                <td>Start Date</td>
                <td>End Date</td>
                <td>Delete</td>
              </tr>
            </thead>
            <tbody>
              {tableData.map((result) => {
                return (
                  <tr key={result._id}>
                    <td>
                      <Button value={result.name} onClick={() => {
                        getUser(result.sponsorId);
                        setShowUserDetailedInfo(true);
                        setShowUserTable(false)
                        setUser(result)
                      }}> {result.company}
                      </Button>
                    </td>
                    <td>{result.email}</td>
                    <td>{result.phone}</td>
                    <td>{result.sponsorshipStartDate!==undefined && 
                         result.sponsorshipStartDate.length>=10 ? result.sponsorshipStartDate.substring(0,10) : ''}</td>
                    <td>{result.sponsorshipExpirationDate!==undefined && 
                         result.sponsorshipExpirationDate.length>=10 ? result.sponsorshipExpirationDate.substring(0,10) : ''}</td>
                    <td>
                      <Button onClick={
                        () => { 
                          if (window.confirm('Are you sure you wish to delete this Sponsor?')){
                              console.log(result.email)
                            deleteUser(result.sponsorId);
                          } 
                        }
                      }> Delete
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
          
      ); 
    } else {
      userTable = <h2>No Sponsor Found(Or click Refresh to refresh the table)</h2>;
    }
  
    return (
      <div>
        <h1>Admin Portal (Sponsor)</h1>
        <div className="adminBanner">Admin Level is: {adminLevel}</div>
        <br />

        <Button onClick={getUsers}>Refresh</Button>
        <Button onClick={
                      () => { 
                        setShowCreateForm(true);
                        setShowUserDetailedInfo(false);
                        setShowUserTable(false);
                      }
                    }> Create Sponsor
        </Button>
        

        <div> 
          {showUserTable===true ? userTable: ''}
          {showCreateForm===true ? createForm: ''}
          {showUserDetailedInfo===true ? userForm: ''}
        </div>

        

       
      </div>
    );
}

export default SponsorManager;