const EventTable = ({
  state,
  zipcode,
  claimedBy
}) => {
  return (
    <div className="event-table">
      <table>
        <tbody>
          <tr>
            <td>State</td>
            <td>Zipcode</td>
            <td>Claimed by</td>
          </tr>
          <tr>
            <td>{state}</td>
            <td>{zipcode}</td>
            <td>{claimedBy || 'Unclaimed'}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EventTable;
