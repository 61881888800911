import axios from 'axios';
import React, {Component} from "react";



class AssignmentForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      eventName: '',
      eventDate: '',
      startTime: '',
      endTime: '',
      freeParking: '',
      specialInstructions: '',
      eventpartner: null,
    }
    console.log(props.eventpartner)
  }

  upcomingEvent = async (e) => {
    e.preventDefault();
    if (window.confirm('Are you sure you make this modification?')){
      console.log(this.state)
  
      const response = await axios.post(
          `${this.props.config.apiUrl}/api/admin/letsfika_eventpartner/${this.props.eventpartner.eventId}/event`, 
          this.state);
      console.log(response.data)
      this.setState({
        eventpartner: response.data.eventPartnerInstance,
        eventName: '',
        eventDate: '',
        startTime: '',
        endTime: '',
        freeParking: '',
        specialInstructions: ''
      });

    }
  }

  render(){
    return (
      <div className='AssignmentForm'>
        <form onSubmit={this.upcomingEvent}>
          <label> Upcoming event for: {this.props.eventpartner.name}</label>
          <br/>

          <label>
              Event Name: 
              <input type="text" value={this.state.eventName} onChange={(e)=>{
                  e.preventDefault(); 
                  this.setState( {eventName: e.target.value} )
                }
              }/>
          </label>
          <br/>

          <label>
            Event Date: 
              <input type="text" value={this.state.eventDate} onChange={(e)=>{
                  e.preventDefault(); 
                  this.setState( {eventDate: e.target.value} )
                }
              }/>
          </label>
          <br/>

          <label>
              Start Time(mm/dd/yy hh:mm): 
              <input type="text" value={this.state.startTime} onChange={(e)=>{
                  e.preventDefault(); 
                  this.setState( {startTime: e.target.value} )
                }
              }/>
          </label>
          <br/>
          
          <label>
              End Time(mm/dd/yy hh:mm): 
              <input type="text" value={this.state.endTime} onChange={(e)=>{
                  e.preventDefault(); 
                  this.setState( {endTime: e.target.value} )
                }
              }/>
          </label>
          <br/>

          <label>
              Free Parking: 
              <input type="text" value={this.state.freeParking} onChange={(e)=>{
                  e.preventDefault(); 
                  this.setState( {freeParking: e.target.value} )
                }
              }/>
          </label>
          <br/>

          <label>
              Special Instructions: 
              <input type="text" value={this.state.specialInstructions} onChange={(e)=>{
                  e.preventDefault(); 
                  this.setState( {specialInstructions: e.target.value} )
                }
              }/>
          </label>
          <br/>

          <input type="submit" value="Submit" />
        
        </form>
      </div>
    );

  }

};

export default AssignmentForm;
