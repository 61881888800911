import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
// import Spinner from '../common/Spinner';
import useProfile from '../hooks/useProfile';

const RoleProtectedRoute = ({ path, children }) => {
  const history = useHistory();
  const { isLoggedIn, profileError } = useProfile();

  useEffect(() => {
    if (profileError) history.push('/');
  }, [profileError, history]);

  return (
    <>
      {isLoggedIn ? <Route path={path} render={() => children} /> : <p>loading</p>}
    </>
  );
};

export default RoleProtectedRoute;
