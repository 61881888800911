import config from '../config';
import axios from 'axios';
import { useState, useEffect } from 'react';
import './profileStyle.scss';

const SponsorTable = () => {
    const [sponsorEmail, setSponsorEmail] = useState('');
    const [sponsorPhone, setSponsorPhone] = useState('');
    const [sponsorZipCode, setSponsorZipCode] = useState('');
    const [sponsorCity, setSponsorCity] = useState('');
    const [sponsorState, setSponsorState] = useState('');
    const [sponsorAddress, setSponsorAddress] = useState('');
    const [sponsorContactName, setSponsorContactName] = useState([]);
    const [sponsorContactJob, setSponsorContactJob] = useState([]);
    const [sponsorCompany, setSponsorCompany] = useState('');
    const [sponsorCompanyUrl, setSponsorCompanyUrl] = useState('');
    const [sponsorLevel, setSponsorLevel] = useState('');
    const [sponsorshipStartDate, setSponsorshipStartDate] = useState('');
    const [sponsorshipExpirationDate, setSponsorshipExpirationDate] = useState('');

    const getSponsorInfo = async () => {
        const res = await axios.get(`${config.apiUrl}/api/user/sponsorInfo`);
        console.log(res);
        if (res.data.sponsor === 1){
            setSponsorEmail(res.data.email);
            setSponsorPhone(res.data.phone);
            setSponsorZipCode(res.data.zipCode);
            setSponsorCity(res.data.city);
            setSponsorState(res.data.state);
            setSponsorAddress(res.data.address);
            setSponsorContactName(res.data.contactName);
            setSponsorContactJob(res.data.contactJob);
            setSponsorCompany(res.data.company);
            setSponsorCompanyUrl(res.data.companyUrl);
            setSponsorLevel(res.data.sponsorLevel)
            setSponsorshipStartDate(res.data.sponsorshipStartDate);
            setSponsorshipExpirationDate(res.data.sponsorshipExpirationDate);
        }
    };

    useEffect(() => {
        getSponsorInfo();
      }, []);

    const updateSponsorInfo = async(e) => {
        e.preventDefault();
        let info = {sponsorEmail, sponsorPhone, sponsorZipCode, 
            sponsorCity, sponsorState, sponsorAddress, sponsorCompany, sponsorCompanyUrl, 
            sponsorLevel, sponsorshipStartDate, sponsorshipExpirationDate, sponsorContactName, sponsorContactJob};
        const res = await axios.patch(`${config.apiUrl}/api/user/sponsorInfo`, info);
        console.log(res);
    }

    let SponsorTable = (        
        <div class="flexbox-container">
            <div className="update user info">
                <h3>Sponsor Profile Table</h3>
                <form onSubmit={updateSponsorInfo}>
                    <label>
                        Email: 
                        <input type="text" value={sponsorEmail} onChange={(e)=>{
                            e.preventDefault(); 
                            setSponsorEmail(e.target.value)}
                            } />
                    </label>
                    <br></br>
    
                    <label>
                        Phone: 
                        <input type="text" value={sponsorPhone} defaultValue={''} onChange={(e)=>{
                            e.preventDefault(); 
                            setSponsorPhone(e.target.value)}
                            } />
                    </label>
                    <br></br>
            
                    <label>
                        Zip Code: 
                        <input type="text" value={sponsorZipCode} onChange={(e)=>{
                            e.preventDefault(); 
                            setSponsorZipCode(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        City: 
                        <input type="text" value={sponsorCity} onChange={(e)=>{
                            e.preventDefault(); 
                            setSponsorCity(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        State: 
                        <input type="text" value={sponsorState} onChange={(e)=>{
                            e.preventDefault(); 
                            setSponsorState(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Address: 
                        <input type="text" value={sponsorAddress} onChange={(e)=>{
                            e.preventDefault(); 
                            setSponsorAddress(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Contact Job: 
                        <input type="text" value={sponsorContactJob} onChange={(e)=>{
                            e.preventDefault(); 
                            setSponsorContactJob(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Contact Name: 
                        <input type="text" value={sponsorContactName} onChange={(e)=>{
                            e.preventDefault(); 
                            setSponsorContactName(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Company: 
                        <input type="text" value={sponsorCompany} onChange={(e)=>{
                            e.preventDefault(); 
                            setSponsorCompany(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Company URL: 
                        <input type="text" value={sponsorCompanyUrl} onChange={(e)=>{
                            e.preventDefault(); 
                            setSponsorCompanyUrl(e.target.value)}
                            } />
                    </label>
                    <label>
                        Start Date: 
                        <input type="text" value={sponsorshipStartDate} onChange={(e)=>{
                            e.preventDefault(); 
                            setSponsorshipStartDate(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Expiration Date: 
                        <input type="text" value={sponsorshipExpirationDate} onChange={(e)=>{
                            e.preventDefault(); 
                            setSponsorshipExpirationDate(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Sponsor Level: 
                        <input type="text" value={sponsorLevel} onChange={(e)=>{
                            e.preventDefault(); 
                            setSponsorLevel(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <input type='submit' value='modify sponsor profile info'/>
                    <br></br>
                    
                </form>
            </div>
        </div>)

        return SponsorTable;
}

export default SponsorTable;