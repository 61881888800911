import React, { useRef } from 'react';
import './dropdown.scss';
import {
  settings,
  logout,
  userProfile,
  Volunteer,
  billingInfo
} from '../icons/links';
import useDetectOutsideClick from '../hooks/useDetectOutsideClick';
import useProfile from '../hooks/useProfile';
// import useLogout from '../hooks/useLogout';

function Dropdown() {
  const { name, profilePic } = useProfile();
  // const logout = useLogout();

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  return (
    <div className="dropdown-container">
      <div className="dropdown-menu-container">
        <button
          type="button"
          onClick={onClick}
          className="dropdown-menu-trigger"
        >
          <span>{name}</span>
          <img className="profile-image" src={profilePic} alt="User avatar" />
        </button>
        <nav
          ref={dropdownRef}
          className={`dropdown-menu ${isActive ? 'active' : 'inactive'}`}
        >
          <ul>
            <li className="underlined">
              <a href="/userProfile">
                <img
                  className="dropdown-icon"
                  src={userProfile}
                  alt="userProfile"
                />
                Profile
              </a>
            </li>
            <li className="underlined">
              <a href="/settings">
                <img
                  className="dropdown-icon"
                  src={settings}
                  alt="settings"
                />
                Settings
              </a>
            </li>
            <li>
              <button type="button">
                <img
                  className="dropdown-icon"
                  src={logout}
                  alt="logout"
                />
                Logout
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Dropdown;
