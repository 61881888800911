const VIDEO = 'VIDEO';
const PODCAST = 'PODCAST';
const BLOG = 'BLOG';
const STATES = ['California', 'Washington'];
const USER_ROLES = {
  REGISTERED_USER: 'REGISTERED_USER',
  CONTENT_CONTRIBUTOR: 'CONTENT_CONTRIBUTOR',
  VOLUNTEER: 'VOLUNTEER',
  EVENT_HOST: 'EVENT_HOST',
  SPONSOR: 'SPONSOR',
  STRATEGIC_PARTNER: 'STRATEGIC_PARTNER',
};

module.exports = { VIDEO, PODCAST, BLOG, STATES, USER_ROLES };
