

import axios from 'axios';
import React, {Component} from "react";


class UpdateUserRoleInfo extends Component {
    constructor (props) {
        super(props)
        this.state = {
            userId: this.props.user.userId,
            strategicPartner: this.props.user.user_roles.includes("strategicpartner") ? true: false,
            volunteer: this.props.user.user_roles.includes("volunteer") ? true: false,
            eventPartner: this.props.user.user_roles.includes("eventpartner") ? true: false,
            sponsor: this.props.user.user_roles.includes("sponsor") ? true: false,
            staff: this.props.user.user_roles.includes("staff") ? true: false,
            contentContributor: this.props.user.user_roles.includes("contentcontributor") ? true: false,
        }
        console.log(this.props.user)
    }

    setUserRoleRequest = async (e) => {
        e.preventDefault();

        let promise = new Promise( (resolve, reject) =>{
            let roleArr = [];
            if (this.state.strategicPartner === 'on' || this.state.strategicPartner === true){
                roleArr.push('strategicpartner');
            }
            if (this.state.volunteer === 'on' || this.state.volunteer === true){
                roleArr.push('volunteer');
            }
            if (this.state.eventPartner === 'on' || this.state.eventPartner === true){
                roleArr.push('eventpartner');
            }
            if (this.state.sponsor === 'on' || this.state.sponsor === true){
                roleArr.push('sponsor');
            }
            if (this.state.staff === 'on' || this.state.staff === true){
                roleArr.push('staff');
            }
            if (this.state.contentContributor === 'on' || this.state.contentContributor === true){
                roleArr.push('contentcontributor');
            }
            const info = {
                user_roles: roleArr
            }
            console.log(info)
            console.log(this.state)
            
            resolve(info); 
        
        });

        promise.then( async (info) => {
            if (window.confirm('Are you sure you make this modification?')){
                console.log(info)
                const response = await axios.patch( 
                    `${this.props.config.apiUrl}/api/admin/user/role/` + this.state.userId, 
                    info 
                );
                console.log(response.data);
            } 
        })

    }

    
    render(){
        return(
            <div>
                <br/>
                <form onSubmit={this.setUserRoleRequest}>
                    <label>
                        Content Contributor: 
                        <input type="checkbox" defaultChecked={this.state.contentContributor} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({contentContributor: e.target.checked})}
                        } />
                    </label>
                    <br/>
                    <label>
                        Volunteer: 
                        <input type="checkbox" defaultChecked={this.state.volunteer} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({volunteer: e.target.checked})}
                        } />
                    </label>
                    <br/>
                    
                    <label>
                        Event Host: 
                        <input type="checkbox" defaultChecked={this.state.eventPartner} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({eventPartner: e.target.checked})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Sponsor: 
                        <input type="checkbox" defaultChecked={this.state.sponsor} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({sponsor: e.target.checked})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Staff: 
                        <input type="checkbox" defaultChecked={this.state.staff} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({staff: e.target.checked})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Strategic Partner: 
                        <input type="checkbox" name='sp' defaultChecked={this.state.strategicPartner} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({strategicPartner: e.target.checked});
                        }} />
                    </label>
                    <br/>

                


                    <input type="submit" value="Submit" />

                </form>
            </div>
        );
    }
};


export default UpdateUserRoleInfo;

