import './navbar.scss';
import React, { useState, useEffect } from 'react';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
} from 'shards-react';
import useProfile from '../hooks/useProfile';
import Profile from './profile';
import config from './../config';
import axios from 'axios';

// TODO: switch active nav based on state
function AppNavbar() {
  const { isLoggedIn } = useProfile();
  const [title, setTitle] = useState();

  useEffect(() => {
    const getTitle = async () => {
      const response = await axios.get(`${config.apiUrl}/api/display/head`);
      setTitle(response.data);
    };
    getTitle();
  }, []);

  return (
    <Navbar type="dark" expand="md">
      <NavbarBrand href="/">Let's Fika - {title}</NavbarBrand>
      <Nav navbar className="m-auto nav-links">
      <NavItem>
          <NavLink active href="/calendar">
            Calendar
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active href="/listen">
            Listen
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/watch">Watch</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/read">Read</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/shop">Shop</NavLink>
        </NavItem>
      </Nav>
      {isLoggedIn ? (
        <Profile />
      ) : (
        <Button className="signup" href="/signup">
          Sign Up
        </Button>
      )}
    </Navbar>
  );
}

export default AppNavbar;
