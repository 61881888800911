import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'shards-ui/dist/css/shards.min.css';
import Upload from './upload/upload';
import AppNavbar from './navbar/navbar';
import Home from './home/home';
import Watch from './watch/watch';
import Listen from './listen/listen';
import Read from './read/read';
import Signup from './auth/Signup';
import Login from './auth/Login';
import AdminPortal from './admin/adminPortal';
import VideoPlayer from './watch/videoPlayer';
import PodcastPlayer from './listen/podcastPlayer';
import AlertBanner from './common/AlertBanner';
import UserProfile from './userProfile/UserProfile';
import Settings from './settings/Settings';
import ProtectedRoute from './routes/protectedRoute';
import CustomerCalendar from './customerCalendar/CustomerCalendar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import axios from 'axios';
import BillingInfo from './userProfile/BillingInfo';
import VolunteerRecords from './userProfile/VolunteerRecords';

function App() {
  axios.defaults.withCredentials = true;

  return (
    <div className="App">
      <AppNavbar />
      <Router>
        <Switch>
          <ProtectedRoute path="/userProfile">
            <UserProfile></UserProfile>
          </ProtectedRoute>
          <ProtectedRoute path="/volunteerRecords">
            <VolunteerRecords></VolunteerRecords>
          </ProtectedRoute>
          <ProtectedRoute path="/billingInfo">
            <BillingInfo></BillingInfo>
          </ProtectedRoute>
          <Route exact path="/" component={Home} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/calendar" component={CustomerCalendar} />
          <Route exact path="/listen" component={Listen} />
          <Route exact path="/watch" component={Watch} />
          <Route exact path="/read" component={Read} />
          <Route exact path="/upload" component={Upload} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/login" component={Login} />
          <Route path="/admin" component={AdminPortal} />
          <Route path="/watch/:videoId" component={VideoPlayer} />
          <Route path="/listen/:episodeId" component={PodcastPlayer} />
        </Switch>
      </Router>
      <AlertBanner />
    </div>
  );
}

export default App;
