import GoogleButtonLogin from './GoogleButtonLogin';
import './Signup.scss';
import { Button, Form, FormInput, FormGroup } from 'shards-react';
import { useHistory } from 'react-router-dom';

import logo from '../images/letsfika.jpg';
import axios from 'axios';
import config from '../config';
import { useState } from 'react';

function Login() {
  const history = useHistory();
  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const login = async () => {
    const response = await axios.post(
      `${config.apiUrl}/api/auth/signin`,
      user
    );
    console.log(response);
    if (response.status === 200) {
      localStorage.setItem('name', response.name)
      history.push('/');
    }
  };

  return (
    <div className="signup-container">
      <img className="signup-logo" src={logo} alt="" />
      <GoogleButtonLogin />

      <Form className="signup-form">
        <FormGroup>
          <label htmlFor="#email">Email Address or Username</label>
          <FormInput
            onChange={(e) => {
              setUser({ ...user, email: e.target.value });
            }}
            id="#email"
            placeholder="Enter your email"
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="#password">Password</label>
          <FormInput
            onChange={(e) => {
              setUser({ ...user, password: e.target.value });
            }}
            type="password"
            id="#confirmPassword"
            placeholder="Confirm password"
          />
        </FormGroup>
        <Button onClick={login} block theme="success">
          Log in
        </Button>
        <p>
          Don't have an account? <a href="/signup">Signup</a>
        </p>
      </Form>
    </div>
  );
}

export default Login;
