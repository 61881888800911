import React, { useState } from 'react';
import { FormRadio, Button } from 'shards-react';
import { USER_ROLES } from '../constants';

const Settings = () => {
  const [role, setRole] = useState(USER_ROLES.REGISTERED_USER);

  const handleChange = (role) => {
    setRole(role);
  };

  return (
    <div className="constrain-width">
      <h1>Settings</h1>
      <h4>Update your role</h4>
      <FormRadio
        checked={role === USER_ROLES.REGISTERED_USER}
        onChange={(e) => {
          handleChange(USER_ROLES.REGISTERED_USER);
        }}
      >
        Registered User
      </FormRadio>
      <FormRadio
        checked={role === USER_ROLES.CONTENT_CONTRIBUTOR}
        onChange={(e) => {
          handleChange(USER_ROLES.CONTENT_CONTRIBUTOR);
        }}
      >
        Content Contributor
      </FormRadio>
      <FormRadio
        checked={role === USER_ROLES.VOLUNTEER}
        onChange={(e) => {
          handleChange(USER_ROLES.VOLUNTEER);
        }}
      >
        Volunteer
      </FormRadio>
      <FormRadio
        checked={role === USER_ROLES.EVENT_HOST}
        onChange={(e) => {
          handleChange(USER_ROLES.EVENT_HOST);
        }}
      >
        Event Host
      </FormRadio>
      <FormRadio
        checked={role === USER_ROLES.SPONSOR}
        onChange={(e) => {
          handleChange(USER_ROLES.SPONSOR);
        }}
      >
        Sponsor
      </FormRadio>
      <FormRadio
        checked={role === USER_ROLES.STRATEGIC_PARTNER}
        onChange={(e) => {
          handleChange(USER_ROLES.STRATEGIC_PARTNER);
        }}
      >
        Strategic Partner
      </FormRadio>
     
      <Button>Update Roles </Button>
      <h4>View a scheduled Fika</h4>
    </div>
  );
};

export default Settings;
