const Columns = [
  {
    name: 'Title',
    field: 'title',
    sortable: false,
  },
  {
    name: 'Date',
    field: 'publishTime',
    sortable: true,
  },
  {
    name: 'Duration',
    field: 'duration',
    sortable: false,
  },
  // {
  //   name: 'Location',
  //   field: 'location',
  // },
  {
    name: 'Category',
    field: 'category',
    sortable: false,
  },
  {
    name: 'Channel',
    field: 'channel',
    sortable: false,
  },
  {
    name: 'Visible',
    field: 'isVisible',
    sortable: false,
  },
  {
    name: 'Featured',
    field: 'isFeatured',
    sortable: false,
  },
  {
    name: 'Home',
    field: 'onHome',
    sortable: false,
  },
];

export default Columns;
