import { useState, useEffect } from 'react';
import userService from '../services/userService';

const useProfile = () => {
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [profileError, setProfileError] = useState(null);

  useEffect(() => {
    userService.getProfile()
      .then((res) => {
        setEmail(res.data.email);
        setName(res.data.name);
        setProfilePic(res.data.profilePic);
        setIsLoggedIn(true);
        console.log(res);
      })
      .catch((err) => {
        setIsLoggedIn(false);
        setProfileError(err);
      });
  }, []);

  return {
    email, name, profilePic, isLoggedIn, profileError,
  };
};

export default useProfile;
