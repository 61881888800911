import Dropzone from './dropzone';

function Upload() {
  return (
    <div>
      <Dropzone></Dropzone>
    </div>
  );
}

export default Upload;
