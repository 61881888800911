import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Alert } from 'shards-react';
import './AlertBanner.css';

const AlertBanner = () => {
  const { alerts } = useSelector(state => state.notifications);
  const [alert, setAlert] = useState({ theme: "", message: "" });
  const [show, setShow] = useState(false);

  // TODO: clear array after the timeout
  useEffect(() => {
    if (alerts.length > 0) {
      setAlert(alerts[alerts.length - 1]);
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 6000);
    }
  }, [alerts]);

  const onClose = () => {
    setShow(false);
  };

  return show ? (
    <Alert className="alert-banner" theme={alert.theme} dismissible={onClose} open={show}>
      {alert.message}
    </Alert>
  ) : null;
};

export default AlertBanner;
