import './watch.scss';
import axios from 'axios';
import { useState, useEffect } from 'react';
import config from '../config';
import Content from '../content/content';

function Watch() {
  const [source, setSource] = useState('Newest');
  const [descriptions, setDescription] = useState([]);
  const [featuredVideo, setFeaturedVideo] = useState();
  const [categories, setCategories] = useState([]);

  const getAllCategories = async () => {
    const response = await axios.get(`${config.apiUrl}/api/labels/categories`);
    // console.log(response.data);
    setCategories(response.data);
  };

  const getFeaturedVideo = async () => {
    const video = await axios.get(config.apiUrl + '/api/videos/featured');
    // console.log(video.data);
    setFeaturedVideo(video.data);
  };

  const getDescriptions = async () => {
    const response = await axios.get(`${config.apiUrl}/api/display/page/watch`);
    // console.log(response.data);
    let result = new Map();
    response.data.forEach((item) => {
      result[item.type] = item.text;
    });
    setDescription(result);
  };

  useEffect(() => {
    getDescriptions();
    getFeaturedVideo();
    getAllCategories();
  }, []);

  return (
    <div className="watch-container">
      <section className="featured-media">
        <div className="constrain-width featured-media-container">
          <div className="featured-media-text-container">
            <h1 className="featured-media-title">{descriptions.Title}</h1>
            <h4 className="featured-media-subtitle">{descriptions.Primary}</h4>
            <div className="category-container">
              {categories.map((item, i) => {
                return (
                  <p className="category-container-item">
                    <span
                      className="category-container-title"
                      onClick={() => {
                        setSource(item.title);
                      }}
                      key={i}
                    >
                      {item.title}
                    </span>
                    {(() => {
                      if (i < categories.length - 1) return '|';
                    })()}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="featured-media-image-container">
            {(() => {
              if (featuredVideo) {
                return (
                  <iframe
                    className="frame"
                    title={featuredVideo._id}
                    src={`https://www.youtube.com/embed/${featuredVideo._id}`}
                    width="400px"
                    height="250px"
                    frameborder="0"
                    allowtransparency="true"
                    allow="encrypted-media"
                  ></iframe>
                );
              }
            })()}
          </div>
        </div>
      </section>
      <Content type="watch" content="videos" title={source} />
    </div>
  );
}

export default Watch;
