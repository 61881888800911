import config from '../config';
import axios from 'axios';
import React, { useEffect, useState, useMemo } from 'react';
import { Button } from 'shards-react';
import './manager.scss';
import { Pagination, Search } from '../components/table';
import UpdateUserInfo from './userComponent/updateUserInfo.js';
import UpdateUserRoleInfo from './userComponent/updateUserRoleInfo';


const UserManager = () => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const ITEMS_PER_PAGE = 25;

  const [showUserTable, setShowUserTable] = useState(true);
  const [showUserDetailedInfo, setShowUserDetailedInfo] = useState(false);




  const [adminLevel, setAdminLevel] = useState('Loading...');
  
  const getAdminLevel = async () => {
      axios.get(`${config.apiUrl}/api/admin/adminlevel`)
    .then( (res)=>{
      console.log(res)
      setAdminLevel(map[res.data.adminLevel]);
    })
    .catch((e)=>{
      console.log(e);
    })
  }


  const tableData = useMemo(() => {
        let computedUsers = users;
    
        if (search) {
          computedUsers = computedUsers.filter((data) =>
            data.name.toLowerCase().includes(search.toLowerCase())
          );
        }
    
        setTotalItems(computedUsers.length);
    
        return computedUsers.slice(
          (currentPage - 1) * ITEMS_PER_PAGE,
          (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
  }, [users, currentPage, search])

  const getUsers = async(e) => {
    // e.preventDefault();
    const response = await axios.get(`${config.apiUrl}/api/admin/users`);
    console.log(response.data);
    setUsers(response.data);
  }

  const getUser = async (n) => {
    const response = await axios.get(`${config.apiUrl}/api/admin/user/`+ n);
    console.log(response.data);
    console.log(n)
    setUser(response.data);
  };

  const refreshTable = getUsers;

  useEffect(() => {
    getUsers();
    getAdminLevel();
  }, []);

  const deleteUser = async (email) => {
    const response = await axios.post(`${config.apiUrl}/api/admin/users/delete/${email}`);
    console.log(response.data);
    refreshTable();
  }

  const options = [
    { value: '0', label: 'Super Admin' },
    { value: '1', label: 'Regular Admin' },
    { value: '2', label: 'Editor' },
    { value: '3', label: 'Normal User' }
  ];

  const map = {
    0: 'Super Admin',
    1: 'Regular Admin',
    2: 'Editor',
    3: 'Normal User'
  }

  let userForm = (
    <div>
      <br/>
        <Button onClick = {() => {
            setShowUserTable(true);
            setShowUserDetailedInfo(false);
            refreshTable();
            }}>Return
        </Button>

      <br/>
      <br/>

      <UpdateUserInfo 
        user={user}
        config={config}>
      </UpdateUserInfo>
      <UpdateUserRoleInfo 
        user={user}
        config={config}>
      </UpdateUserRoleInfo>
    
    </div>
  );



 
  function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  let userTable = '';
      if (tableData.length !== 0) {
        userTable = (
          <div>
            <br/>
  
            <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            <div className="col-md-6">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
  
            <table>
              <thead>
                <tr>
                  <td>UserAvatar</td>
                  <td>Email</td>
                  <td>Name</td>
                  <td>Username</td>
                  <td>Role/Privilege Level </td>
                  <td>Delete</td>
                </tr>
              </thead>
              <tbody>
                {tableData.map((result) => {
                  return (
                    <tr key={result._id}>
                      <td>
                        <img src={result.profilePic} width="50" height="50"></img>
                      </td>
                      <td><Button value={result.email} onClick={() => {
                          getUser(result.userId);
                          setShowUserDetailedInfo(true);
                          setShowUserTable(false)
                          setUser(result)
                        }}> {result.email}
                        </Button></td>
                      <td>{result.name}</td>
                      <td>{result.userName}</td>
                      <td>{isNumeric(result.adminLevel) ? map[result.adminLevel] : result.adminLevel}</td>
                      <td>
                        <Button onClick={
                          () => { 
                            if (window.confirm('Are you sure you wish to delete this User?')){
                              deleteUser(result.email);
                            } 
                          }
                        }> Delete
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
            
        ); 
      } else {
        userTable = <h2>No Event Partner Found(Or click Refresh to refresh the table)</h2>;
      }

  return (
    <div>
      <h1>Admin Portal (User)</h1>
      <div className="adminBanner">Admin Level is: {adminLevel}</div>
      <br />
      <Button onClick={getUsers}>Refresh</Button>
      <div className="admin">
      
            {showUserTable===true ? userTable: ''}
            {showUserDetailedInfo===true ? userForm: ''}
        
      </div>

    </div>
  );
}

export default UserManager;