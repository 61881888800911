import config from '../config';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { useState, useEffect } from 'react';
import BillingInfo from './BillingInfo.js';
import VolunteerRecords from './VolunteerRecords.js';
import Favorites from '../favorites/favorites.js';
import './profileStyle.scss';

const VolunteerTable = () => {
    const [volunteerName, setVolunteerName] = useState('');
    const [volunteerEmail, setVolunteerEmail] = useState('');
    const [volunteerPhone, setVolunteerPhone] = useState('');
    const [volunteerZipCode, setVolunteerZipCode] = useState('');
    const [volunteerCity, setVolunteerCity] = useState('');
    const [volunteerState, setVolunteerState] = useState('');
    const [volunteerAddress, setVolunteerAddress] = useState('');
    const [volunteerAvailableDays, setVolunteerAvailableDays] = useState([]);
    const [volunteerTime, setVolunteerTime] = useState('');

    const getVolunteerInfo = async () => {
        const res = await axios.get(`${config.apiUrl}/api/user/volunteerInfo`);
        console.log(res);
        if (res.data.volunteer === 1){
            setVolunteerName(res.data.name);
            setVolunteerEmail(res.data.email);
            setVolunteerPhone(res.data.phone);
            setVolunteerZipCode(res.data.zipCode);
            setVolunteerCity(res.data.city);
            setVolunteerState(res.data.state);
            setVolunteerAddress(res.data.address);
            setVolunteerAvailableDays(res.data.availableDays.join(''));
            setVolunteerTime(res.data.preferedTime);
        }
    };

    useEffect(() => {
        getVolunteerInfo();
      }, []);

    const updateVolunteerInfo = async(e) => {
        e.preventDefault();
        let listedDays = volunteerAvailableDays.split('').map(Number);
        let info = {volunteerName, volunteerEmail, volunteerPhone, volunteerZipCode,
                    volunteerCity, volunteerState, volunteerAddress, listedDays, volunteerTime};
        const res = await axios.patch(`${config.apiUrl}/api/user/volunteerInfo`, info);
        console.log(res);
    }

    let volunteerTable = (        
        <div class="flexbox-container">
            <div className="update user info">
                <h3>Volunteer Profile Table</h3>
                <form onSubmit={updateVolunteerInfo}>
                    <label >
                        Volunteer Name: 
                        <input type="text" style={ {width:'250px'} } value={volunteerName} onChange={(e)=>{
                            e.preventDefault(); 
                            setVolunteerName(e.target.value)}
                            } />
                    </label>
                    <br></br>
    
                    <label>
                        Volunteer Email: 
                        <input type="text" value={volunteerEmail} onChange={(e)=>{
                            e.preventDefault(); 
                            setVolunteerEmail(e.target.value)}
                            } />
                    </label>
                    <br></br>
    
                    <label>
                        Phone: 
                        <input type="text" value={volunteerPhone} defaultValue={''} onChange={(e)=>{
                            e.preventDefault(); 
                            setVolunteerPhone(e.target.value)}
                            } />
                    </label>
                    <br></br>
            
                    <label>
                        Zip Code: 
                        <input type="number" value={volunteerZipCode} onChange={(e)=>{
                            e.preventDefault(); 
                            setVolunteerZipCode(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        City: 
                        <input type="text" value={volunteerCity} onChange={(e)=>{
                            e.preventDefault(); 
                            setVolunteerCity(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        State: 
                        <input type="text" value={volunteerState} onChange={(e)=>{
                            e.preventDefault(); 
                            setVolunteerState(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Address: 
                        <input type="text" value={volunteerAddress} onChange={(e)=>{
                            e.preventDefault(); 
                            setVolunteerAddress(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Available Days: 
                        <input type="number" value={volunteerAvailableDays} onChange={(e)=>{
                            e.preventDefault(); 
                            setVolunteerAvailableDays(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Prefered Time: 
                        <input type="text" value={volunteerTime} onChange={(e)=>{
                            e.preventDefault(); 
                            setVolunteerTime(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <input type='submit' value='modify volunteer profile info'/>
                    <br></br>
                    
                </form>
            </div>
        </div>)

        return volunteerTable;
}

export default VolunteerTable;