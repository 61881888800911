import React, { useState, useEffect } from 'react';
import config from '../config';
import axios from 'axios';
import './labels.scss';

const Category = (props) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getAllCategories = async () => {
      const response = await axios.get(
        `${config.apiUrl}/api/labels/categories`
      );
      console.log(response.data);
      setCategories(response.data);
    };
    getAllCategories();
  }, []);

  return (
    <div className="category-container">
      {categories.map((item, i) => {
        return (
          <p className="category-container-item">
            <a href={`/${props.type}/category/${item.title}`} key={i}>
              {item.title}
            </a>
            {(() => {
              if (i < categories.length - 1) return '|';
            })()}
          </p>
        );
      })}
    </div>
  );
};

export default Category;
