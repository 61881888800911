import axios from 'axios';
import React, {Component} from "react";

class CreateNewVolunteer extends Component {
    constructor (props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            phone: '',
            startDate: '',
            endDate: '',
            secondaryEmail: ''

        }
    }

    createVolunteer = async (e) => {
        e.preventDefault();
        const info = {...this.state}
        try{
            const res = await axios.post(`${this.props.config.apiUrl}/api/admin/volunteer`, info)
            this.setState({
                name: '',
                email: '',
                phone: '',
                startDate: '',
                endDate: '',
                secondaryEmail: ''
            })
            console.log(res);
        } catch (e) {
            console.log(e);
        }
    }

    render(){
        return(
            <div>
                <label>Register New Volunteer below: </label>
                <br/>
                <form onSubmit={this.createVolunteer}>
                    <label>
                        Name(required): 
                        <input type="text" value={this.state.name} onChange={(e)=>{
                                e.preventDefault(); 
                                this.setState({name: e.target.value})}
                                } />
                    </label>
                    <br/>
                    <label>
                        Email(required): 
                        <input type="text" value={this.state.email} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({email: e.target.value})}
                            } />
                    </label>
                    <br/>
                    <label>
                        Secondary Email: 
                        <input type="text" value={this.state.secondaryEmail} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({secondaryEmail: e.target.value})}
                            } />
                    </label>
                    <br/>
                    <label>
                        Phone: 
                        <input type="text" value={this.state.phone} onChange={(e)=>{
                                e.preventDefault(); 
                                this.setState({phone: e.target.value})}
                                } />
                    </label>
                    <br/>
                    <label>
                        Start Date: (mm/dd/yy)
                        <input type="text" value={this.state.startDate} onChange={(e)=>{
                                e.preventDefault(); 
                                this.setState({startDate: e.target.value})}
                                } />
                    </label>
                    <br/>
                    <label>
                        End Date: (mm/dd/yy)
                        <input type="text" value={this.state.endDate} onChange={(e)=>{
                                e.preventDefault(); 
                                this.setState({endDate: e.target.value})}
                                } />
                    </label>

                    <input type="submit" value="Submit" />

                </form>
            </div>
        );
    }
};

export default CreateNewVolunteer;