import axios from 'axios';
import React, {Component} from "react";


class UpdateEventPartnerInfo extends Component {
    constructor (props) {
        super(props)
        this.state = {


            eventId: this.props.user.eventId,
            email: this.props.user.email,
            phone: this.props.user.phone,
            zipCode: this.props.user.zipCode,

            city: this.props.user.city,
            state: this.props.user.state,
            address: this.props.user.address,
            company: this.props.user.company,
            companyUrl: this.props.user.companyUrl,

            job: this.props.user.job,
            preferedEventDate: this.props.user.preferedEventDate,
            preferedStartTime: this.props.user.preferedStartTime,
            preferedEndTime: this.props.user.preferedEndTime,
            name: this.props.user.name,

            freeParking: this.props.user.freeParking
        }
        console.log(this.props.sponsor)
    }

    setUserRequest = async (e) => {
        e.preventDefault();
        const info = {
            ...this.state
        }
        console.log(info)

        if (window.confirm('Are you sure you make this modification?')){
          const response = await axios.patch(
                `${this.props.config.apiUrl}/api/admin/letsfika_eventpartner/` + this.state.eventId,
              {info} );
          console.log(response.data);
        } 
        
    }

    
    render(){
        return(
            <div>
                <label>Register New Event Partner below: </label>
                <br/>
                <form onSubmit={this.setUserRequest}>
                    <label>
                        Name(required): 
                        <input type="text" value={this.state.name} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({name: e.target.value})}
                        } />
                    </label>
                    <br/>
                    <label>
                        Company Name(required): 
                        <input type="text" value={this.state.company} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({company: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Company Url: 
                        <input type="text" value={this.state.companyUrl} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({companyUrl: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Job Title: 
                        <input type="text" value={this.state.job} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({job: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Prefered Event Date: 
                        <input type="text" value={this.state.preferedEventDate} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({preferedEventDate: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Prefered Start Date: 
                        <input type="text" value={this.state.preferedStartTime} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({preferedStartTime: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Prefered End Time: 
                        <input type="text" value={this.state.preferedEndTime} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({preferedEndTime: e.target.value})}
                        } />
                    </label>
                    <br/>
                    
                    <label>
                        Email(required): 
                        <input type="text" value={this.state.email} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({email: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Phone: 
                        <input type="text" value={this.state.phone} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({phone: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Address: 
                        <input type="text" value={this.state.address} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({address: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        City: 
                        <input type="text" value={this.state.city} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({city: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        State: 
                        <input type="text" value={this.state.state} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({state: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Zipcode: 
                        <input type="text" value={this.state.zipCode} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({zipCode: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Free Parking: 
                        <input type="text" value={this.state.freeParking} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({freeParking: e.target.value})}
                        } />
                    </label>
                    <br/>


                    <input type="submit" value="Submit" />

                </form>
            </div>
        );
    }
};


export default UpdateEventPartnerInfo;

