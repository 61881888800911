


import axios from 'axios';
import React, {Component} from "react";


class UpdateStrategicPartnerInfo extends Component {
    constructor (props) {
        super(props)
        this.state = {
            strategicId: this.props.strategicpartner.strategicId,
            email: this.props.strategicpartner.email,
            phone: this.props.strategicpartner.phone,
            zipCode: this.props.strategicpartner.zipCode,
            city: this.props.strategicpartner.city,
            state: this.props.strategicpartner.state,
            address: this.props.strategicpartner.address,
            company: this.props.strategicpartner.company,
            companyUrl: this.props.strategicpartner.companyUrl,
            category: this.props.strategicpartner.category,
            startDate: this.props.strategicpartner.startDate,
            endDate: this.props.strategicpartner.endDate,
            contactName: this.props.strategicpartner.contactName,
            contactJob: this.props.strategicpartner.contactJob,
            attnBillingName: this.props.strategicpartner.attnBillingName,

            paidInGoodStanding: this.props.strategicpartner.paidInGoodStanding,
            partnershipActive: this.props.strategicpartner.partnershipActive,
            channelActive: this.props.strategicpartner.channelActive,
            interestedInRenewing: this.props.strategicpartner.interestedInRenewing,
            specialInstructionsComments: this.props.strategicpartner.specialInstructionsComments
        }
        console.log(this.props.strategicpartner)
    }

    setUserRequest = async (e) => {
        e.preventDefault();
        const info = {
            ...this.state
        }
        console.log(info)

        if (window.confirm('Are you sure you make this modification?')){
          const response = await axios.patch(
              `${this.props.config.apiUrl}/api/admin/letsfika_strategicpartner/` + this.state.strategicId, 
              {info} );
          console.log(response.data);
        } 
        
    }

    
    render(){
        return(
            <div>
                <form onSubmit={this.setUserRequest}>
                    <label>
                        Company Name(required): 
                        <input type="text" value={this.state.company} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({company: e.target.value})}
                        } />
                    </label>
                    <label>
                        Paid / In Good Standing: 
                        <input type="text" value={this.state.paidInGoodStanding} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({paidInGoodStanding: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Company Url(required): 
                        <input type="text" value={this.state.companyUrl} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({companyUrl: e.target.value})}
                        } />
                    </label>
                    <label>
                        Partnership Active: 
                        <input type="text" value={this.state.partnershipActive} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({partnershipActive: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Category:
                        <input type="text" value={this.state.category} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({category: e.target.value})}
                        } />
                    </label>
                    <label>
                        Channel Active: 
                        <input type="text" value={this.state.channelActive} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({channelActive: e.target.value})}
                        } />
                    </label>
                    <br/>
                    

                    <label>
                        Sponsorship Start Date: 
                        <input type="text" value={this.state.startDate} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({startDate: e.target.value})}
                        } />
                    </label>
                    <label>
                        Interested In Renewing: 
                        <input type="text" value={this.state.interestedInRenewing} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({interestedInRenewing: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Sponsorship End Date: 
                        <input type="text" value={this.state.endDate} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({endDate: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Key Contact Name: 
                        <input type="text" value={this.state.contactName} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({contactName: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Key Contact Job Title: 
                        <input type="text" value={this.state.contactJob} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({contactJob: e.target.value})}
                        } />
                    </label>
                    <br/>
                    
                    <label>
                        Email(required): 
                        <input type="text" value={this.state.email} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({email: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Phone: 
                        <input type="text" value={this.state.phone} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({phone: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Billing Address: 
                        <input type="text" value={this.state.address} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({address: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        City: 
                        <input type="text" value={this.state.city} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({city: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        State: 
                        <input type="text" value={this.state.state} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({state: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Zipcode: 
                        <input type="text" value={this.state.zipCode} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({zipCode: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Special Instructions / Comments: 
                        <input type="text" value={this.state.specialInstructionsComments} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({specialInstructionsComments: e.target.value})}
                        } />
                    </label>
                    <br/>

                    

                    <input type="submit" value="Submit" />

                </form>
            </div>
        );
    }


}


export default UpdateStrategicPartnerInfo;

