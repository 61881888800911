import { Component } from "react";



export default class VolunteerRecords extends Component {

    render(){
        return(
            <div>
                <label>TODO: Some Volunteer Records</label>
            </div>
        );
    }
}