import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from 'react-share';

const share = (props) => {
  const style = {
    display: 'flex',
    justifyContent: 'center',
    '& Button': {
      width: '20px!important',
    },
  };

  return (
    <div style={style}>
      <EmailShareButton url={props.url}>
        <EmailIcon size={40} round={true}></EmailIcon>
      </EmailShareButton>
      <FacebookShareButton url={props.url}>
        <FacebookIcon size={40} round={true}></FacebookIcon>
      </FacebookShareButton>
      <LinkedinShareButton url={props.url}>
        <LinkedinIcon size={40} round={true}></LinkedinIcon>
      </LinkedinShareButton>
      <TwitterShareButton url={props.url}>
        <TwitterIcon size={40} round={true}></TwitterIcon>
      </TwitterShareButton>
    </div>
  );
};

export default share;
