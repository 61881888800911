/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';
import Moment from 'moment';
import './read.scss';
import { Category } from '../labels/labels';

const calculateDaysAgo = (postDate) => {
  const durationValue = Moment.duration(
    Moment(new Date()).diff(Moment(postDate))
  );
  const attemptDays = Math.floor(durationValue.asDays());
  if (attemptDays < 2) {
    return `${Math.floor(durationValue.asHours())} hours`;
  } else if (attemptDays > 31) {
    return `${Math.floor(durationValue.asMonths())} months`;
  } else {
    return `${attemptDays} days`;
  }
};

function Read() {
  const [articles, setArticles] = useState([]);
  const [descriptions, setDescription] = useState([]);
  const [featuredArticles, setFeaturedArticles] = useState();

  const getVisibleArticles = async () => {
    const articles = await axios.get(config.apiUrl + '/api/podcasts/visible');
    console.log(articles.data);
    setArticles(articles.data);
  };

  const getFeaturedArticles = async () => {
    const articles = await axios.get(config.apiUrl + '/api/podcasts/featured');
    console.log(articles.data);
    setFeaturedArticles(articles.data);
  };

  const getDescriptions = async () => {
    const response = await axios.get(`${config.apiUrl}/api/display/page/read`);
    console.log(response.data);
    setDescription(response.data);
  };

  useEffect(() => {
    getDescriptions();
    getVisibleArticles();
    getFeaturedArticles();
  }, []);

  return (
    <div>
      <section className="featured-media">
        <div className="constrain-width featured-media-container">
          <div className="featured-media-text-container">
            {descriptions.map((item, i) => {
              if (item.type === 'Title') {
                return (
                  <h1 className="featured-media-title" key={'read' + i}>
                    {item.text}
                  </h1>
                );
              } 
              return (
                <h4 className="featured-media-subtitle" key={'read' + i}>
                  {item.text}
                </h4>
              );
            })}
            <Category type="blogs" />
          </div>
          <div className="featured-media-image-container-temp"></div>
        </div>
      </section>
      <div className="listen-container">
        {/* <h1 className="listen-container-title">Channels</h1>
        <Channel link="listen" /> */}
      </div>
    </div>
  );
}

export default Read;
