// import ReactPlayer from 'react-player/lazy';
import axios from 'axios';
import config from '../config';
import { Button } from 'shards-react';
import { VIDEO } from '../constants';
import { useDispatch } from 'react-redux';
import { createAlert } from '../common/AlertBannerSlice';
import { useState, useEffect } from 'react';
import './videoPlayer.scss';
import Share from '../components/share';
import { format } from 'date-fns';

const VideoPlayer = (props) => {
  const dispatch = useDispatch();
  const [videoInfo, setVideoInfo] = useState('');

  useEffect(() => {
    const getVideo = async () => {
      console.log(props.match.params.videoId);
      const video = await axios.get(
        `${config.apiUrl}/api/videos/id/${props.match.params.videoId}`
      );
      console.log(video.data);
      setVideoInfo(video.data);
    };
    getVideo();
  }, [props.match.params.videoId]);

  const addToFavorites = async () => {
    try {
      const res = await axios.post(`${config.apiUrl}/api/favorites`, {
        contentId: props.match.params.videoId,
        contentType: VIDEO,
      });
      if (res.status === 200) {
        dispatch(
          createAlert({
            message: 'Added to favorites.',
            theme: 'success',
          })
        );
      }
    } catch (err) {
      dispatch(
        createAlert({
          message: err.response.data.error,
          theme: 'danger',
        })
      );
    }
  };

  const clearFavorites = async () => {
    const res = await axios.get(`${config.apiUrl}/api/favorites/empty`);
    console.log(res);
  };

  return (
    <div className="video-player-container">
      <div className="video-player">
        <iframe
          title={props.match.params.videoId}
          src={`https://www.youtube.com/embed/${props.match.params.videoId}`}
          frameborder="0"
          width="100%"
          height="650px"
          allowtransparency="true"
          allow="encrypted-media"
        ></iframe>
      </div>

      {/* <ReactPlayer
        width="100%"
        height="60vh"
        className="video-player"
        url={`https://www.youtube.com/watch?v=${props.match.params.videoId}`}
      ></ReactPlayer> */}

      <div className="video-info">
        <h3>{videoInfo.title}</h3>
        <h6>Author:&ensp;{videoInfo.author}</h6>
        <h6>Location:&ensp;{videoInfo.location}</h6>
        <h6>
          Date:&ensp;
          {videoInfo.publishTime
            ? format(new Date(videoInfo.publishTime), 'MM/dd/yyyy')
            : null}
        </h6>
        <h6>Category:&ensp;{videoInfo.category}</h6>
        <h6>Channel:&ensp;{videoInfo.channel}</h6>
        <h6>Tags:&ensp;{videoInfo.tags}</h6>
        <h6>Description:&ensp;{videoInfo.description}</h6>
      </div>

      <Share
        url={`https://www.youtube.com/watch?v=${props.match.params.videoId}`}
      />

      <div className="share-container">
        <Button onClick={addToFavorites}>Add to Favorites!</Button>
        <Button onClick={clearFavorites}>
          Clear Favorites (debug mode only)
        </Button>
      </div>
    </div>
  );
};

export default VideoPlayer;
