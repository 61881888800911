import { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { FormSelect } from 'shards-react';
import CustomerCalendarForms from './CustomerCalendarForms';
import moment from 'moment';
import axios from 'axios';
import config from '../config';
import { USER_ROLES } from '../constants';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const CustomerCalendar = () => {
  const [events, setEvents] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(undefined);
  const [currentRole, setCurrentRole] = useState(USER_ROLES.REGISTERED_USER);

  const getEvents = async () => {
    const response = await axios.get(`${config.apiUrl}/api/events`);
    const eventsArray = [];
    for (let event of response.data) {
      eventsArray.push({
        eventId: event.eventId,
        start: moment(event.eventStartDTO).toDate(),
        end: moment(event.eventEndDTO).toDate(),
        state: event.state,
        title: event.claimedBy ? event.claimedBy : event.city,
        city: event.city,
        zipcode: event.zipcode,
      });
    }
    setEvents(eventsArray);
  };

  useEffect(() => {
    getEvents();
  }, []);

  const displayEvent = (event) => {
    console.log(event);
    setCurrentEvent(event);
  };

  const handleSelectChange = (event) => {
    console.log(event);
    setCurrentRole(event.target.value);
  };

  return (
    <div className="constrain-width">
      <Calendar
        localizer={localizer}
        events={events}
        onSelectEvent={(event) => {
          displayEvent(event);
        }}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
      />
      {currentEvent ? (
        <div>
          <br />
          <h5>Select Application Role</h5>
          <FormSelect onChange={handleSelectChange}>
            <option value={USER_ROLES.REGISTERED_USER}>Registered User</option>
            <option value={USER_ROLES.CONTENT_CONTRIBUTOR}>
              Content Contributor
            </option>
            <option value={USER_ROLES.VOLUNTEER}>Volunteer</option>
            <option value={USER_ROLES.EVENT_HOST}>Event Host</option>
            <option value={USER_ROLES.SPONSOR}>Sponsor</option>
            <option value={USER_ROLES.STRATEGIC_PARTNER}>
              Strategic Partner
            </option>
          </FormSelect>
          <h4>Application for {currentEvent.title} event</h4>
          <CustomerCalendarForms userRole={currentRole} event={currentEvent}/>
        </div>
      ) : null}
      <br />
      <br />
    </div>
  );
};

export default CustomerCalendar;
