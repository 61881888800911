

import axios from 'axios';
import React, {Component} from "react";


class UpdateUserInfo extends Component {
    constructor (props) {
        super(props)
        this.state = {
            userId: this.props.user.userId,
            email: this.props.user.email,
            secondaryEmail: this.props.user.secondaryEmail,
            name: this.props.user.name,
            status: this.props.user.status,
            adminLevel: this.props.user.adminLevel,
            createdAt: this.props.user.createdAt,
            favoriteBlogs: this.props.user.favoriteBlogs,
            favoritePodcasts: this.props.user.favoritePodcasts,
            favoriteVideos: this.props.user.favoriteVideos,
            googleId: this.props.user.googleId,
            phone: this.props.user.phone,
            profilePic: this.props.user.profilePic,
            updatedAt: this.props.user.updatedAt,
            userName: this.props.user.userName,
            watchList: this.props.user.watchList,

        }
        console.log(this.props.sponsor)
    }

    setUserRequest = async (e) => {
        e.preventDefault();
        const info = {
            ...this.state
        }
        console.log(info)

        if (window.confirm('Are you sure you make this modification?')){
          const response = await axios.patch( 
              `${this.props.config.apiUrl}/api/admin/user/` + this.state.userId, 
              {info} );
          console.log(response.data);
        } 
        
    }

    
    render(){
        return(
            <div>
                <label>Register New Event Partner below: </label>
                <br/>
                <form onSubmit={this.setUserRequest}>
                    <label>
                        Name(required): 
                        <input type="text" value={this.state.name} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({name: e.target.value})}
                        } />
                    </label>
                    <br/>
                    
                    <label>
                        Email(required): 
                        <input type="text" value={this.state.email} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({email: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Phone: 
                        <input type="text" value={this.state.phone} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({phone: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Secondary Email (optional): 
                        <input type="text" value={this.state.secondaryEmail} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({secondaryEmail: e.target.value})}
                        } />
                    </label>
                    <br/>

                    <label>
                        Status: 
                        <input type="text" value={this.state.status} onChange={(e)=>{
                            e.preventDefault(); 
                            this.setState({status: e.target.value})}
                        } />
                    </label>
                    <br/>

                


                    <input type="submit" value="Submit" />

                </form>
            </div>
        );
    }
};


export default UpdateUserInfo;

