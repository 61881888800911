import config from '../config';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { useState, useEffect, Component } from 'react';

class Favorites extends Component{

  constructor(props){
    super(props);
    this.state = {

    }

  }
  // useEffect(() => {
    
  // }, []);

  render(){
    return (
      <div>
        <h1>Favorite Videos</h1>
        {this.props.favoriteVideos.map((videoId) => (
          <ReactPlayer
            width="300px"
            height="200px"
            className="player"
            url={`https://www.youtube.com/watch?v=${videoId}`}
          ></ReactPlayer>
        ))}
        <h1>Favorite Podcasts</h1>
        {this.props.favoritePodcasts.map((podcastId) => (
          <p>{podcastId}</p>
        ))}
        <h1>Favorite Blogs</h1>
        {this.props.favoriteBlogs.map((blogId) => (
          <p>{blogId}</p>
        ))}
      </div>
    );

  }
  
};



export default Favorites;
