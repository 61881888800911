import axios from 'axios';
import config from '../config';
import { useState, useEffect, useMemo } from 'react';
import './content.scss';
import { Search } from '../components/table';
import Select from 'react-select';
import SortOptions from './sortOption';

import Moment from 'moment';
const calculateDaysAgo = (postDate) => {
  const durationValue = Moment.duration(
    Moment(new Date()).diff(Moment(postDate))
  );
  const attemptDays = Math.floor(durationValue.asDays());
  if (attemptDays < 2) {
    return `${Math.floor(durationValue.asHours())} hours`;
  } else if (attemptDays > 31) {
    return `${Math.floor(durationValue.asMonths())} months`;
  } else {
    return `${attemptDays} days`;
  }
};

const Content = (props) => {
  const [contents, setContents] = useState([]);
  const [channels, setChannels] = useState([]);
  const [search, setSearch] = useState();
  const [sorting, setSorting] = useState(-1);
  const [selectedChannel, setSelectedChannel] = useState();

  const getVisibleContent = async (type) => {
    const visibleContent = await axios.get(
      config.apiUrl + `/api/${type}/visible`
    );
    // console.log(visibleContent.data);
    setContents(visibleContent.data);
  };

  const getCategoryContent = async (type, category) => {
    const content = await axios.get(
      config.apiUrl + `/api/${type}/category/${category}`
    );
    // console.log(content.data);
    setContents(content.data);
  };

  const getAllChannels = async () => {
    const response = await axios.get(`${config.apiUrl}/api/labels/channels`);
    let result = [];
    result.push({ value: 'default', label: 'All Channels' });
    response.data.forEach((data) => {
      result.push({ value: data.title, label: data.title });
    });
    setChannels(result);
  };

  useEffect(() => {
    function getContent(content, category) {
      if (category === 'Newest') {
        getVisibleContent(content);
      } else {
        getCategoryContent(content, category);
      }
      getAllChannels();
    }
    getContent(props.content, props.title);
  }, [props]);

  const contentData = useMemo(() => {
    let allContents = contents;
    if (search && search.length > 0) {
      allContents = allContents.filter((data) =>
        data.title.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (selectedChannel && selectedChannel.value !== 'default') {
      allContents = allContents.filter(
        (data) => data.channel === selectedChannel.value
      );
    }
    if (sorting) {
      allContents = allContents.sort(
        (a, b) => sorting * a.publishTime.localeCompare(b.publishTime)
      );
    }
    return allContents;
  }, [sorting, search, selectedChannel, contents]);

  return (
    <div className="container">
      <div className="filter">
        <div className="filter-search">
          <Search
            onSearch={(value) => {
              setSearch(value);
            }}
          />
        </div>
        <Select
          className="filter-select"
          options={channels}
          onChange={(value) => {
            setSelectedChannel(value);
          }}
          defaultValue={{ value: 'default', label: 'Channels' }}
        />
        <label className="filter-sort float-lg-right">Sort by:</label>
        <Select
          className="filter-select"
          options={SortOptions}
          onChange={(selected) => {
            setSorting(selected.value);
          }}
          defaultValue={SortOptions[0]}
        />
      </div>
      <h1 className="container-title">{props.title}</h1>
      <div className="content-container">
        {contentData.map((content, idx) => {
          let id = props.type === 'videos' ? content.youtubeId : content._id;
          return (
            <div className="content-container-item" key={idx}>
              <a href={`/${props.type}/${id}`}>
                <img
                  className="thumbnail"
                  src={content.thumbnailUrl}
                  alt="thumbnail"
                />
                <h4 className="content-container-item-title">
                  {content.title}
                </h4>
              </a>
              <h6 className="content-container-item-publish-date">
                posted {calculateDaysAgo(content.publishTime)} ago
              </h6>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Content;
