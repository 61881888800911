import axios from 'axios';
import config from '../config';

const getProfile = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`${config.apiUrl}/api/user/getProfile`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });

const exports = { getProfile };
export default exports;
