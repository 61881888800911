// import { Button } from 'shards-react';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import config from './../config';
import axios from 'axios';
import './home.scss';
// import ReactPlayer from 'react-player/lazy';

function getPlayer(id, type) {
  if (type === 'podcast') {
    return (
      <iframe
        className="frame"
        title={id}
        src={`https://open.spotify.com/embed-podcast/episode/${id}`}
        width="500px"
        height="250px"
        frameborder="0"
        allowtransparency="true"
        allow="encrypted-media"
      ></iframe>
    );
  } else if (type === 'video') {
    return (
      <iframe
        className="frame"
        title={id}
        src={`https://www.youtube.com/embed/${id}`}
        width="500px"
        height="350px"
        frameborder="0"
        allowtransparency="true"
        allow="encrypted-media"
      ></iframe>
    );
  } else if (type === 'blog') {
    return null;
  } else {
    return (
      <>
        <div className="player-conatiner-blog"></div>
        <div className="player-conatiner-blog"></div>
      </>
    );
  }
}

function Home() {
  const history = useHistory();
  const [description, setDescription] = useState({});
  const [podcasts, setPodcasts] = useState([]);
  const [videos, setVideos] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const getDescriptions = async () => {
    const response = await axios.get(`${config.apiUrl}/api/display/page/home`);
    // console.log(response.data);
    let result = new Map();
    response.data.forEach((item) => {
      result[item.type] = item.text;
    });
    setDescription(result);
  };

  const getPodcasts = async () => {
    const response = await axios.get(
      `${config.apiUrl}/api/display/content/listen`
    );
    setPodcasts(response.data);
  };

  const getVideos = async () => {
    const response = await axios.get(
      `${config.apiUrl}/api/display/content/watch`
    );
    setVideos(response.data);
  };

  const getBlogs = async () => {
    const response = await axios.get(
      `${config.apiUrl}/api/display/content/read`
    );
    setBlogs(response.data);
  };

  useEffect(() => {
    getDescriptions();
    getPodcasts();
    getVideos();
    getBlogs();
  }, []);

  return (
    <>
      <section className="banner-image-container">
        <h1 className="header-title">{description.Title}</h1>
        <h4 className="header-subtitle">{description.Primary}</h4>
      </section>
      <section className="constrain-width home-nav-section">
        <h4 className="home-nav-text">{description.Secondary}</h4>
      </section>
      <section className="constrain-width home-podcast-section">
        <div className="section-container">
          <h1
            className="section-container-right"
            onClick={() => history.push('/listen')}
          >
            Listen
          </h1>
          <div className="description">
            <div className="description-item-left"></div>
            <h5 className="description-item-right">{description.Listen}</h5>
          </div>

          <div className="player-conatiner">
            {podcasts.map((podcast, i) => {
              if (i < 2) {
                return (
                  <div className="player-conatiner-podcast" key={i}>
                    {getPlayer(podcast._id, 'podcast')}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </section>
      <section className="constrain-width home-video-section">
        <div className="section-container">
          <h1
            className="section-container-left"
            onClick={() => history.push('/watch')}
          >
            Watch
          </h1>
          <div className="description">
            <h5 className="description-item-left">{description.Watch}</h5>
            <div className="description-item-right"></div>
          </div>
          <div className="player-conatiner">
            {videos.map((video, i) => {
              if (i < 2) {
                return (
                  <div className="player-conatiner-video" key={i}>
                    {getPlayer(video._id, 'video')}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </section>
      <section className="constrain-width home-read-section">
        <div className="section-container">
          <h1
            className="section-container-right"
            onClick={() => history.push('/read')}
          >
            Read
          </h1>
          <div className="description">
            <div className="description-item-left"></div>
            <h5 className="description-item-right">{description.Read}</h5>
          </div>
          <div className="player-conatiner">
            {blogs.map((blog, i) => {
              if (i < 2) {
                return (
                  <div className="player-conatiner-blog" key={i}>
                    {getPlayer(blog._id, 'blog')}
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </div>
      </section>
      <section className="constrain-width home-about-section">
        <div className="home-about-title">
          <h2>About Let’s Fika</h2>
          <h4>{description.About}</h4>
        </div>
      </section>
    </>
  );
}

export default Home;
