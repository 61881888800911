import config from '../config';
import axios from 'axios';
import { useState, useEffect } from 'react';
import './profileStyle.scss';

const EventPartnerTable = () => {
    const [eventPartnerName, setEventPartnerName] = useState('');
    const [eventPartnerEmail, setEventPartnerEmail] = useState('');
    const [eventPartnerPhone, setEventPartnerPhone] = useState('');
    const [eventPartnerZipCode, setEventPartnerZipCode] = useState('');
    const [eventPartnerCity, setEventPartnerCity] = useState('');
    const [eventPartnerState, setEventPartnerState] = useState('');
    const [eventPartnerAddress, setEventPartnerAddress] = useState('');
    const [eventPartnerJob, setEventPartnerJob] = useState([]);
    const [eventPartnerCompany, setEventPartnerCompany] = useState('');
    const [eventPartnerCompanyUrl, setEventPartnerCompanyUrl] = useState('');
    const [eventPartnerPreferedEventDate, setEventPartnerPreferedEventDate] = useState('');
    const [eventPartnerPreferedStartTime, setEventPartnerPreferedStartTime] = useState('');
    const [eventPartnerPreferedEndTime, setEventPartnerPreferedEndTime] = useState('');
    const [eventPartnerFreeParking, setEventPartnerFreeParking] = useState('');

    const getEventPartnerInfo = async () => {
        const res = await axios.get(`${config.apiUrl}/api/user/eventPartnerInfo`);
        console.log(res);
        if (res.data.eventPartner === 1){
            setEventPartnerName(res.data.name);
            setEventPartnerEmail(res.data.email);
            setEventPartnerPhone(res.data.phone);
            setEventPartnerZipCode(res.data.zipCode);
            setEventPartnerCity(res.data.city);
            setEventPartnerState(res.data.state);
            setEventPartnerAddress(res.data.address);
            setEventPartnerJob(res.data.job);
            setEventPartnerCompany(res.data.company);
            setEventPartnerCompanyUrl(res.data.companyUrl);
            setEventPartnerPreferedEventDate(res.data.preferedEventDate);
            setEventPartnerPreferedStartTime(res.data.preferedStartTime);
            setEventPartnerPreferedEndTime(res.data.preferedEndTime);
            setEventPartnerFreeParking(res.data.freeParking);
        }
    };

    useEffect(() => {
        getEventPartnerInfo();
      }, []);

    const updateEventPartnerInfo = async(e) => {
        e.preventDefault();
        // let FreeParking = false;
        // if (eventPartnerFreeParking === 'true'){
        //     FreeParking = true;
        // }
        let info = {eventPartnerName, eventPartnerEmail, eventPartnerPhone, eventPartnerZipCode, 
            eventPartnerCity, eventPartnerState, eventPartnerAddress, eventPartnerCompany, eventPartnerCompanyUrl, 
            eventPartnerJob, eventPartnerPreferedEventDate, eventPartnerPreferedStartTime, eventPartnerPreferedEndTime, eventPartnerFreeParking};
        const res = await axios.patch(`${config.apiUrl}/api/user/eventPartnerInfo`, info);
        console.log(res);
    }

    let EventPartnerTable = (        
        <div class="flexbox-container">
            <div className="update user info">
                <h3>Event Partner Profile Table</h3>
                <form onSubmit={updateEventPartnerInfo}>
                    <label >
                        Name: 
                        <input type="text" style={ {width:'250px'} } value={eventPartnerName} onChange={(e)=>{
                            e.preventDefault(); 
                            setEventPartnerName(e.target.value)}
                            } />
                    </label>
                    <br></br>
    
                    <label>
                        Email: 
                        <input type="text" value={eventPartnerEmail} onChange={(e)=>{
                            e.preventDefault(); 
                            setEventPartnerEmail(e.target.value)}
                            } />
                    </label>
                    <br></br>
    
                    <label>
                        Phone: 
                        <input type="text" value={eventPartnerPhone} defaultValue={''} onChange={(e)=>{
                            e.preventDefault(); 
                            setEventPartnerPhone(e.target.value)}
                            } />
                    </label>
                    <br></br>
            
                    <label>
                        Zip Code: 
                        <input type="number" value={eventPartnerZipCode} onChange={(e)=>{
                            e.preventDefault(); 
                            setEventPartnerZipCode(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        City: 
                        <input type="text" value={eventPartnerCity} onChange={(e)=>{
                            e.preventDefault(); 
                            setEventPartnerCity(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        State: 
                        <input type="text" value={eventPartnerState} onChange={(e)=>{
                            e.preventDefault(); 
                            setEventPartnerState(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Address: 
                        <input type="text" value={eventPartnerAddress} onChange={(e)=>{
                            e.preventDefault(); 
                            setEventPartnerAddress(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Job: 
                        <input type="text" value={eventPartnerJob} onChange={(e)=>{
                            e.preventDefault(); 
                            setEventPartnerJob(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Company: 
                        <input type="text" value={eventPartnerCompany} onChange={(e)=>{
                            e.preventDefault(); 
                            setEventPartnerCompany(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Company URL: 
                        <input type="text" value={eventPartnerCompanyUrl} onChange={(e)=>{
                            e.preventDefault(); 
                            setEventPartnerCompanyUrl(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Event Date: 
                        <input type="text" value={eventPartnerPreferedEventDate} onChange={(e)=>{
                            e.preventDefault(); 
                            setEventPartnerPreferedEventDate(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        Start Date: 
                        <input type="text" value={eventPartnerPreferedStartTime} onChange={(e)=>{
                            e.preventDefault(); 
                            setEventPartnerPreferedStartTime(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <label>
                        End Date: 
                        <input type="text" value={eventPartnerPreferedEndTime} onChange={(e)=>{
                            e.preventDefault(); 
                            setEventPartnerPreferedEndTime(e.target.value)}
                            } />
                    </label>
                    <br></br>
                    <input type='submit' value='modify event partner profile info'/>
                    <br></br>
                    
                </form>
            </div>
        </div>)

        return EventPartnerTable;
}

export default EventPartnerTable;