
import axios from 'axios';
import React, {Component} from "react";
import { Button } from 'shards-react';


class PastEventsEventPartner extends Component {
    constructor (props) {
        super(props)
        this.state = {
            events: this.props.events

        }
        console.log(this.state.events)
    }

    handleEventRecordModification = async (e) =>{
        e.preventDefault();
        
        console.log(this.state.events)
        const eventId = this.props.eventId;
        const config = this.props.config;
        // TODO: update database
        if (window.confirm('Are you sure to make this modification?')){
            const res = await axios.patch(
                `${config.apiUrl}/api/admin/letsfika_eventpartner/${eventId}/events`,
                {events: this.state.events})
        }
    }

    deleteRecord = async (eId) => {
        console.log(this.state.events)
        let newEvents = this.state.events
        newEvents.splice(eId, 1); //delete 'eId'th event record
        for (let i=0; i<newEvents.length; i++){
            newEvents[i].eId = i;
        }
        this.setState({events: newEvents})
        const eventId = this.props.eventId;
        const config = this.props.config;
        // TODO: update database
        const res = await axios.patch(
            `${config.apiUrl}/api/admin/letsfika_eventpartner/${eventId}/events`,
            {events: this.state.events})
    }
    
    
    render(){
        return (
            <div>
                {this.state.events===undefined ?  'The Event partner has not hosted any event yet.' :
                    <form  onSubmit={this.handleEventRecordModification}>
                        <table>
                            <thead>
                                <tr>
                                    <td>Event Name</td>
                                    <td>Event Date</td>
                                    <td>Start Time</td>
                                    <td>End Time</td>
                                    <td>Free Parking</td>
                                    <td>Special Instructions</td>
                                    <td>Delete</td>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.events.map( (event) => {
                                    return (
                                        <tr key={"eventIndex_"+event.eId}>
                                            <td>
                                                <input type="text" value={event.eventName} onChange={
                                                    (e)=>{
                                                        var events = [...this.state.events] 
                                                        events[parseInt(event.eId)].eventName = e.target.value;
                                                        this.setState({ events })
                                                    }
                                                }/>
                                            </td>
                                            <td>
                                                <input type="text" value={event.eventDate} onChange={
                                                    (e)=>{
                                                        var events = [...this.state.events] 
                                                        events[parseInt(event.eId)].eventDate = e.target.value;
                                                        this.setState({ events })
                                                    }
                                                }/>
                                            </td>
                                            <td>
                                                <input type="text" value={event.startTime} onChange={
                                                    (e)=>{
                                                        var events = [...this.state.events] 
                                                        events[parseInt(event.eId)].startTime = e.target.value;
                                                        this.setState({ events })
                                                    }
                                                }/>
                                            </td>
                                            <td>
                                                <input type="text" value={event.endTime} onChange={
                                                    (e)=>{
                                                        var events = [...this.state.events] 
                                                        events[parseInt(event.eId)].endTime = e.target.value;
                                                        this.setState({ events })
                                                    }
                                                }/>
                                            </td>
                                            <td>
                                                <input type="text" value={event.freeParking} onChange={
                                                    (e)=>{
                                                        var events = [...this.state.events] 
                                                        events[parseInt(event.eId)].freeParking = e.target.value;
                                                        this.setState({ events })
                                                    }
                                                }/>
                                            </td>
                                            <td>
                                                <input type="text" value={event.specialInstructions} onChange={
                                                    (e)=>{
                                                        var events = [...this.state.events] 
                                                        events[parseInt(event.eId)].specialInstructions = e.target.value;
                                                        this.setState({ events })
                                                    }
                                                }/>
                                            </td>
                                            <td>
                                                <Button onClick={
                                                        () => { 
                                                        if (window.confirm('Are you sure you wish to delete this event record?')){
                                                            this.deleteRecord(event.eId);
                                                        } 
                                                        }
                                                    }> Delete
                                                </Button>
                                            </td>
                                        </tr>
                                    )})
                            }
                            </tbody>
                        </table>

                        <input type="submit" value="Modify" />
                    </form>
                }
            </div>
        )
    }


}




export default PastEventsEventPartner;